import React, { Component } from "react";
import {
	Menu,
	Row,
	Col,
	Popover,
	Button,
	Switch as Toggler,
	Avatar,
	Input,
} from "antd";
import {
	ShowTheLocationWithRouter,
	ContentPresenter,
	DefaultAvatar,
	SimpleHeader,
} from "./components";
import {
	Dashboard,
	Create,
	ApproveClaimDetails,
	ApproveClaim,
	ViewAllClaims,
	ViewClaim,
	ApproveClaimByHRHead,
	ApproveClaimByHR,
	ApproveClaimByWelfare,
	CloseLoanRequest,
	ApproveLoanSettlement,
	CurrentLoansList,
	LoanDetailsReport,
	UploadDocuments,
} from "./pages";
import {
	logOutAsLionUser,
	lionUserManager,
	userManager,
} from "./repos/UserRepo";
import DefaultLayout from "./layouts/DefaultLayout";
import { withRouter, Switch, Route, Link, Redirect } from "react-router-dom";
import "./App.css";
import { RouteConfig } from "./route.config";
import {
	AppstoreOutlined,
	PictureOutlined,
	ClockCircleOutlined,
	FormOutlined,
	DiffOutlined,
	SnippetsOutlined,
	ColumnWidthOutlined,
	BookOutlined,
	FileSearchOutlined,
	FileAddOutlined,
} from "@ant-design/icons";
import "./ProtectedApp.css";
import logo from "../images/logo.png";
import { LionUser } from "user-manager";
const { SubMenu } = Menu;

class ProtectedApp extends Component<any, any> {
	constructor(props: any) {
		super(props);
		this.state = { collapsed: true };
	}

	toggle() {
		this.setState({
			collapsed: !this.state.collapsed,
		});
	}

	render() {
		return (
			<DefaultLayout
				header={<ProtectedAppHeader />}
				sidebar={
					<ProtectedAppSidebar
						collapsed={this.state.collapsed}
						toggle={this.toggle.bind(this)}
					/>
				}
				isSidebarCollapsed={this.state.collapsed}>
				{/* <FilterHeaderStrip /> */}
				<div className='breadcrumbs-container'>
					<ShowTheLocationWithRouter />
				</div>
				<Switch>
					<Route exact path={RouteConfig.PROTECTED_MAIN_DASHBOARD}>
						<ContentPresenter>
							<div>
								<Dashboard />
							</div>
						</ContentPresenter>
					</Route>
					<Route exact path={RouteConfig.PROTECTED_CREATE}>
						<ContentPresenter>
							{" "}
							<Create />
						</ContentPresenter>
					</Route>
					<Route exact path={RouteConfig.PROTECTED_APPROVE_CLAIM}>
						<ContentPresenter>
							{" "}
							<ApproveClaim />
						</ContentPresenter>
					</Route>
					<Route exact path={RouteConfig.PROTECTED_VIEW_ALL_CLAIM}>
						<ContentPresenter>
							{" "}
							<ViewAllClaims />
						</ContentPresenter>
					</Route>
					<Route exact path={RouteConfig.PROTECTED_VIEW_CLAIM}>
						<ContentPresenter>
							{" "}
							<ViewClaim />
						</ContentPresenter>
					</Route>
					<Route exact path={RouteConfig.PROTECTED_EDIT_CLAIM}>
						<ContentPresenter>
							{" "}
							<ApproveClaimByHR />
						</ContentPresenter>
					</Route>
					<Route exact path={RouteConfig.PROTECTED_APPROVE_CLAIM_BY_HR_HEAD}>
						<ContentPresenter>
							{" "}
							<ApproveClaimByHRHead />
						</ContentPresenter>
					</Route>

					<Route exact path={RouteConfig.PROTECTED_APPROVE_CLAIM_BY_HR}>
						<ContentPresenter>
							{" "}
							<ApproveClaimByHR />
						</ContentPresenter>
					</Route>
					<Route exact path={RouteConfig.PROTECTED_APPROVE_CLAIM_BY_WELFARE}>
						<ContentPresenter>
							{" "}
							<ApproveClaimByWelfare />
						</ContentPresenter>
					</Route>
					<Route exact path={RouteConfig.PROTECTED_CLOSE_REQUEST}>
						<ContentPresenter>
							{" "}
							<CloseLoanRequest />
						</ContentPresenter>
					</Route>
					<Route exact path={RouteConfig.PROTECTED_APPROVE_LOAN_SETTLEMENT}>
						<ContentPresenter>
							{" "}
							<ApproveLoanSettlement />
						</ContentPresenter>
					</Route>
					<Route exact path={RouteConfig.PROTECTED_CURRENT_LOAN_LIST_REPORT}>
						<ContentPresenter>
							{" "}
							<CurrentLoansList />
						</ContentPresenter>
					</Route>
					<Route exact path={RouteConfig.PROTECTED_LOAN_DETAILS_REPORT}>
						<ContentPresenter>
							{" "}
							<LoanDetailsReport />
						</ContentPresenter>
					</Route>
					<Route exact path={RouteConfig.PROTECTED_DOCUMENTS}>
						<ContentPresenter>
							{" "}
							<UploadDocuments />
						</ContentPresenter>
					</Route>

					<Route path={RouteConfig.PROTECTED_ROOT}>
						<Redirect to={{ pathname: RouteConfig.PROTECTED_MAIN_DASHBOARD }} />
					</Route>
				</Switch>
			</DefaultLayout>
		);
	}
}

class FilterHeaderStrip extends Component<any> {
	constructor(props: any) {
		super(props);
	}

	render() {
		return (
			<Col className='header-strip-container'>
				<Row>
					<Input />
				</Row>
			</Col>
		);
	}
}

class ProtectedAppHeader extends Component<any> {
	render() {
		return (
			<Row
				justify='end'
				align='middle'
				className='main-header-container'
				gutter={35}>
				<Col className='main-header-notification-icon'>
					{/* <Popover content={data && <NotificationContainer data={data} />} placement="topLeft" trigger="click">
                        <Badge count={data && data.length}>
                            <div className="header-icon"><BellFilled /></div>
                        </Badge>
                    </Popover> */}
				</Col>
				<Col>
					<Popover
						content={userManager.user && <UserPopover user={userManager.user} />}
						placement='topLeft'
						trigger='click'>
						<div>
							<DefaultAvatar user={lionUserManager.user} />
						</div>
					</Popover>
				</Col>
			</Row>
		);
	}
}

export class UserPopover extends Component<{ user: LionUser }> {
	render() {
		return (
			<Row
				className='user-popover-container'
				gutter={[10, 10]}
				align='middle'
				justify='center'>
				{lionUserManager && (
					<Col>
						<Row align='middle' justify='center' className='user-popover-avatar-name'>
							<Avatar src={lionUserManager.user.data.User.imagePaths} size={70} />
						</Row>
						<Row
							style={{ marginTop: 6 }}
							align='middle'
							justify='center'
							className='user-popover-avatar-role'
							gutter={[10, 10]}>
							{lionUserManager.user.data.User.firstName +
								" " +
								lionUserManager.user.data.User.lastName}
						</Row>
						<Row
							style={{ marginTop: 6 }}
							align='middle'
							justify='center'
							className='user-popover-avatar-role'
							gutter={[10, 10]}>
							{lionUserManager.user.data.User.designationName}
						</Row>
						<Row
							style={{ marginTop: 1 }}
							align='middle'
							justify='center'
							className='user-popover-avatar-email'
							gutter={[10, 10]}>
							{lionUserManager.user.data.User.email}
						</Row>
						<Row style={{ marginTop: 6 }} align='middle' justify='center'>
							<Button
								onClick={logOutAsLionUser}
								shape='round'
								className='user-popover-user-sign-out-button'>
								Sign Out
							</Button>
						</Row>
					</Col>
				)}
			</Row>
		);
	}
}

class NotificationContainer extends Component<any> {
	render() {
		return (
			<Row style={{ width: 400 }}>
				<Col span={24}>
					<SimpleHeader title='Notifications' />
				</Col>
				<Col className='notification-container'>{}</Col>
			</Row>
		);
	}
}

function canAccess(authority: string): boolean {
	return lionUserManager.user.data.AUTHORITIES.includes(authority)
	// return true
}

class ProtectedAppSidebar extends Component<
	{ collapsed: boolean; toggle: () => void } | any
> {
	render() {
		return (
			<React.Fragment>
				<div className='sidebar'>
					<div className='flex-center logo'>
						<img src={logo} style={{ width: "100%" }}></img>
					</div>

					<Menu mode='inline' defaultSelectedKeys={["0"]}>
						<Menu.Item key='0'>
							<Link to={RouteConfig.PROTECTED_MAIN_DASHBOARD}>
								<AppstoreOutlined style={{ margin: 2 }} />
								<span>Dashboard</span>
							</Link>
						</Menu.Item>

						<Menu.Item key='1'>
							<Link to={RouteConfig.PROTECTED_CREATE}>
								<FileAddOutlined style={{ margin: 2 }} />
								<span>Create Loan</span>
							</Link>
						</Menu.Item>
						<Menu.Item key='2'>
							<Link to={RouteConfig.PROTECTED_APPROVE_CLAIM}>
								<ColumnWidthOutlined style={{ margin: 2 }} />
								<span>Approve Loan</span>
							</Link>
						</Menu.Item>
						{canAccess("HR Approval") && (
							<Menu.Item key='4'>
								<Link to={RouteConfig.PROTECTED_APPROVE_CLAIM_BY_HR}>
									<SnippetsOutlined style={{ margin: 2 }} />
									<span>HR Approval</span>
								</Link>
							</Menu.Item>
						)}
						{canAccess("HR HOD Approval") && (
							<Menu.Item key='5'>
								<Link to={RouteConfig.PROTECTED_APPROVE_CLAIM_BY_HR_HEAD}>
									<DiffOutlined style={{ margin: 2 }} />
									<span> Head Of HR Approval</span>
								</Link>
							</Menu.Item>
						)}
						{canAccess("Welfare Approval") && (
							<Menu.Item key='6'>
								<Link to={RouteConfig.PROTECTED_APPROVE_CLAIM_BY_WELFARE}>
									<FormOutlined style={{ margin: 2 }} />
									<span>Welfare Approval</span>
								</Link>
							</Menu.Item>
						)}
						{canAccess("Close Loan") && (
							<Menu.Item key='7'>
								<Link to={RouteConfig.PROTECTED_CLOSE_REQUEST}>
									<ClockCircleOutlined style={{ margin: 2 }} />
									<span>Close Loan Request</span>
								</Link>
							</Menu.Item>
						)}
						{canAccess("Loan Settlement") && (
							<Menu.Item key='8'>
								<Link to={RouteConfig.PROTECTED_APPROVE_LOAN_SETTLEMENT}>
									<ColumnWidthOutlined style={{ margin: 2 }} />
									<span>Settlements</span>
								</Link>
							</Menu.Item>
						)}
						{canAccess("Loan Reports View") && <SubMenu
							key='9'
							title={
								<span>
									<BookOutlined />
									<span>Reports</span>
								</span>
							}>
							<Menu.Item key='10'>
								<Link to={RouteConfig.PROTECTED_CURRENT_LOAN_LIST_REPORT}>
									<span>Current Active Loan List</span>
								</Link>
							</Menu.Item>
							<Menu.Item key='11'>
								<Link to={RouteConfig.PROTECTED_LOAN_DETAILS_REPORT}>
									<span>Loan Details Report</span>
								</Link>
							</Menu.Item>
						</SubMenu>}
						<Menu.Item key='8'>
							<Link to={RouteConfig.PROTECTED_DOCUMENTS}>
								<FileSearchOutlined style={{ margin: 2 }} />
								<span>Documents</span>
							</Link>
						</Menu.Item>
					</Menu>
				</div>

				<span className='sidebar-toggle-button'>
					<Toggler
						size='small'
						defaultChecked={this.props.collapsed}
						onChange={this.props.toggle}
					/>
				</span>
			</React.Fragment>
		);
	}
}

// const ProtectedAppHeaderWithUser = connect((state => state), UserActionTypes)(ProtectedAppHeader);

export default withRouter(ProtectedApp);
