import React, { FunctionComponent, Component, createRef } from "react";
import {
	Table,
	Tag,
	message,
	Spin,
	Drawer,
	Button,
	DatePicker,
	Input,
	Row,
	Select,
	Col,
	Dropdown,
} from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { ClaimRepoActions } from "../../repos/ClaimRepo";
import ComponentToPrint from "../../components/ComponentToPrint";
// import logo from '../logo.png'
import ReactToPrint from "react-to-print";
import "../style.css";
import { lionUserManager } from "../../repos/UserRepo";
import { any, bool, boolean, ValidationError } from "@hapi/joi";
import Moment from "moment";
import { getStatusByCode, statusMap } from "../statusMapper";
import { PrintableLoanDetailReport } from "./PrintableLoanDetailReport";
import { TableColumnSlectorSearch } from "../ApproveClaim";
const { Column } = Table;
const { TextArea } = Input;

// export const stateValues = [
// 	"DRAFT",
// 	"PENDING",
// 	"GUARANTOR APPROVED ",
// 	"HR APPROVED",
// 	"HEAD OF HR APPROVED",
// 	"HEAD OF WELFARE APPROVED",
// 	// "CHEQUE IS PROCESSING",
// 	"REJECTED",
// 	"RESUBMITED",
// 	"ACTIVE",
// 	"LOAN SETTLED",
// 	"APPLIED FOR SETTLEMENT",
// 	"SETTLEMENT REJECTED",
// ];

class LoanDetailsReport extends React.Component<any, any> {
	tableRef: React.RefObject<HTMLDivElement>;
	constructor(props: any) {
		super(props);
		this.tableRef = createRef();
		this.state = {
			//   loans : [],
			loading: false,
			visibleView: false,
			reason: false,
			visible: false,
			cheque: "",
			dateRange: undefined,
			touched: true,
			department: undefined,
			id: "",
		};
	}

	componentDidMount() {
		this.fetchInitData();
	}

	fetchInitData = async () => {
		this.setState({ loading: true });

		// await this.props.getDepartment();
		await this.fetchAll();
		await this.props.getCompanies();

		this.setState({ loading: false });
	};

	// onChange = async (date: any, dateString: any) => {
	// 	this.setState({
	// 		toData: Moment(new Date(dateString)).format("YYYY-MM-DD"),
	// 	});
	// };

	// onChangeFromData = async (date: any, dateString: any) => {
	// 	this.setState({
	// 		fromData: Moment(new Date(dateString)).format("YYYY-MM-DD"),
	// 	});
	// };

	searchByDepartment = async () => {};

	fetchAll = async () => {
		this.setState({ loading: true });

		const form = new FormData();

		form.append("startDate", "");
		form.append("endDate", "");

		await this.props.getLoanDetailsReport(form);

		this.setState({ loading: false });
	};

	handleFilter = async () => {
		this.setState({ loading: true });

		const form = new FormData();

		// filter by department
		if (this.state.department) {
			if (this.state.dateRange) {
				form.append("startDate", this.state.dateRange[0].format("YYYY-MM-DD"));
				form.append("endDate", this.state.dateRange[1].format("YYYY-MM-DD"));

				await this.props.getLoanDetailsReportByDepartment(
					this.state.department.syncStructureLevelCode,
					form
				);
			} else {
				message.error("Please provide a date range");
			}
		}

		// filter by just date
		if (!this.state.department) {
			if (this.state.dateRange) {
				form.append("startDate", this.state.dateRange[0].format("YYYY-MM-DD"));
				form.append("endDate", this.state.dateRange[1].format("YYYY-MM-DD"));
			} else {
				form.append("startDate", "");
				form.append("endDate", "");
			}

			await this.props.getLoanDetailsReport(form);
		}

		this.setState({ loading: false });
		return;
	};

	render() {
		return (
			<div>
				<Spin
					size='small'
					tip='Data Loading'
					spinning={this.state.loading}
					delay={500}>
					<h3>Loan Details Report</h3>
					<Row justify='space-between' gutter={10}>
						<Col>
							<Row gutter={10}>
								<Col>
									<DatePicker.RangePicker
										allowClear
										// disabled={this.state.department || !this.state.touched}
										onChange={(range) =>
											this.setState({
												touched: true,
												dateRange: range,
											})
										}
									/>
								</Col>
								<Col>
									<Select
										placeholder='Companies'
										allowClear
										// disabled={this.state.dateRange || !this.state.touched}
										onChange={(value) => {
											let department = this.props.ClaimRepo.comapanies[value as any];
											this.setState({
												touched: true,
												department,
											});
										}}
										style={{ width: 220 }}
										options={(this.props.ClaimRepo.comapanies ?? []).map(
											(value: any, i: number) => ({
												label: value.syncStructureLevelName,
												value: i,
											})
										)}
									/>
								</Col>
								<Col>
									<Button onClick={this.handleFilter} type='primary'>
										Filter
									</Button>
								</Col>
							</Row>
						</Col>

						<Col>
							<ReactToPrint
								trigger={() => {
									// NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
									// to the root node of the returned component as it will be overwritten.
									return (
										<Button href='#' type='default'>
											Print this
										</Button>
									);
								}}
								content={() => this.tableRef.current}
							/>
							{/* <div style={{ display: "none" }}>
								<ComponentToPrint
									data={this.state.loans}
									ref={(el) => (this.componentRef = el)}
								/>
							</div> */}
						</Col>
					</Row>
					<PrintableLoanDetailReport
						hidden
						ref={this.tableRef}
						dataSource={(this.props.ClaimRepo.loanDetailsReport ?? []).reverse()}
					/>
					<div style={{ opacity: this.state.loading ? 0.8 : 1 }}>
						<Table
							style={{ marginTop: 26, padding: 5 }}
							dataSource={(this.props.ClaimRepo.loanDetailsReport ?? []).reverse()}>
							<Column
								title='Loan Id'
								dataIndex='id'
								render={(value, item, index) => value}
							/>
							<Column width='100' title='Epf No' dataIndex='epfNo' key='epfNo' />
							<Column width='100' title='Employee Name' dataIndex='name' key='name' />
							<Column
								width='100'
								title='Loan Amount'
								dataIndex='loanAmount'
								render={(value, item, index) =>
									"Rs. " +
									(Math.round(value * 100) / 100)
										.toFixed(2)
										.toString()
										.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
								}
							/>
							<Column
								width='100'
								title='Installment Amount'
								dataIndex='installmentAmount'
								render={(value, item, index) =>
									"Rs. " +
									(Math.round(value * 100) / 100)
										.toFixed(2)
										.toString()
										.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
								}
							/>
							<Column
								title='Interest Rate'
								dataIndex='interestRate'
								key='interestRate'
							/>
							<Column
								filters={Array.from(statusMap.entries()).map(([value, { title }]) => ({
									text: title,
									value: value.toString(),
								}))}
								onFilter={(data, record: any) => {
									return record.processingStatus == data;
								}}
								title='Status'
								dataIndex='processingStatus'
								render={(value, item, index) => getStatusByCode(parseInt(value))?.title}
							/>
						</Table>
					</div>
				</Spin>
			</div>
		);
	}
}

const WrappedLoanDetailsReport = LoanDetailsReport;
const withDashboardRepo = connect(
	(state: any) => state,
	ClaimRepoActions
)(WrappedLoanDetailsReport);
export default withDashboardRepo;
