type StatusMapData = {
	title: string;
	color?: string;
};

export const statusMap = new Map<number, StatusMapData>([
	[-8, { title: "CHEQUE IS READY" }],
	[-6, { title: "DELETED" }],
	[-2, { title: "PENDING HR APPROVAL" }],
	[0, { title: "DRAFT" }],
	// [1, { title: "PENDING" }],
	[1, { title: "PENDING GUARANTORS APPROVAL" }],
	[2, { title: "GUARANTOR APPROVED " }],
	[3, { title: "PENDING HEAD OF HR APPROVAL" }],
	[4, { title: "PENDING WELFARE APPROVAL" }],
	// [5, { title: "HEAD OF WELFARE APPROVED" }],
	[5, { title: "CHEQUE IS PROCESSING" }],
	[6, { title: "REJECTED" }],
	[7, { title: "RESUBMITED" }],
	[8, { title: "ACTIVE" }],
	[9, { title: "LOAN MANUALLY SETTLED" }],
	[10, { title: "APPLIED FOR SETTLEMENT" }],
	[11, { title: "SETTLEMENT REJECTED" }],
	[13, { title: "LOAN AUTOMATICALLY SETTLED" }],
]);

export const getStatusByCode = (code: number) => {
	return statusMap.get(code);
};
