import { FuelGrade, FuelType, EngineCapacity, EngineMakes, VehicleModel, Vehicle, VehicleType } from './models/Vehicle'
import { lionUserManager } from './UserRepo'
import { texts } from '../texts.config'

interface RepoState {
    allVehicles: Array<Vehicle>
    allVehicleModels: Array<VehicleModel>
    allVehicleCapacities: Array<EngineCapacity>
    allVehicleMakes: Array<EngineMakes>
    allFuelTypes: Array<FuelType>
    allFuelGrades: Array<FuelGrade>
    allVehicleTypes: Array<VehicleType>
    allEligibleFuelGrades: Array<FuelGrade>
}

const defaultState: RepoState = {
    allVehicles: [],
    allVehicleModels: [],
    allVehicleCapacities: [],
    allVehicleMakes: [],
    allFuelTypes: [],
    allFuelGrades: [],
    allVehicleTypes: [],
    allEligibleFuelGrades: []
}

export interface IVehicleRepoActions {
    getAllVehicles: () => Promise<any>
    getAllVehicleModels: () => Promise<any>,
    getAllVehicleCapacities: () => Promise<any>,
    getAllVehicleMakes: () => Promise<any>,
    getAllFuelGrades: () => Promise<any>,
    getAllFuelTypes: () => Promise<any>
    getAllVehicleTypes: () => Promise<any>
    getAllEligibleFuelGrades: () => Promise<any>
}

export const Repo = (state: RepoState = defaultState, action: { type: VehicleRepoActionTypes, payload: any }) => {
    switch (action.type) {
        case VehicleRepoActionTypes.GET_ALL_VEHICLE_MODELS: {
            return { ...state, allVehicleModels: action.payload }
        }
        case VehicleRepoActionTypes.GET_ALL_VEHICLE_MAKES: {
            return { ...state, allVehicleMakes: action.payload }
        }
        case VehicleRepoActionTypes.GET_ALL_VEHICLE_CAPACITIES: {
            return { ...state, allVehicleCapacities: action.payload }
        }
        case VehicleRepoActionTypes.GET_ALL_FUEL_GRADES: {
            return { ...state, allFuelGrades: action.payload }
        }
        case VehicleRepoActionTypes.GET_ALL_FUEL_TYPES: {
            return { ...state, allFuelTypes: action.payload }
        }
        case VehicleRepoActionTypes.GET_ALL_VEHICLE_TYPES: {
            return { ...state, allVehicleTypes: action.payload }
        }
        case VehicleRepoActionTypes.GET_ALL_VEHICLES: {
            return { ...state, allVehicles: action.payload }
        }
        case VehicleRepoActionTypes.GET_ALL_ELIGIBLE_FUEL_GRADES: {
            return { ...state, allEligibleFuelGrades: action.payload }
        }
        default: return state;
    }
}

export const VehicleRepoActions = (dispatch: any): IVehicleRepoActions => ({
    getAllEligibleFuelGrades: async () => {
        try {
            const res = await lionUserManager.authenticationManager.post('/fuelClaim/FuelClaim/GetEligibleFuelGrads', {
                employeeId: lionUserManager.user.data.USER_ID,
            });

            console.log(res)

            if (res.status == 200) {
                dispatch({
                    type: VehicleRepoActionTypes.GET_ALL_ELIGIBLE_FUEL_GRADES,
                    payload: res.data.map((data: any) => new FuelGrade({ id: data.fuelGrade.id, name: data.fuelGrade.name }))
                }) 
            } else {
                throw new Error(texts.DEFAULT_ERROR_MESSAGE)
            }
        } catch (err) {
            throw err;
        }
    },
    getAllVehicles: async () => {
        try {
            const res = await lionUserManager.authenticationManager.get('/fuelClaim/vehicle/AllList');

            if (res.status == 200) {
                dispatch({
                    type: VehicleRepoActionTypes.GET_ALL_VEHICLES,
                    payload: res.data.map((_data: any) => new Vehicle({
                        id: _data.id,
                        plateNumber: _data.plateNumber,
                        status: _data.status,
                        enterDate: _data.enterDate,
                        type: new VehicleType({ id: _data.vehicalType.id, name: _data.vehicalType.name }),
                        engineCapacity: new EngineCapacity({ id: _data.engineCapacity.id, name: _data.engineCapacity.name }),
                        fuelGrade: new FuelGrade({ id: _data.fuelGrade.id, name: _data.fuelGrade.name }),
                        fuelType: new FuelType({ id: _data.fuelType.id, name: _data.fuelType.name })
                    }))
                });
            } else {
                throw new Error(texts.DEFAULT_ERROR_MESSAGE)
            }
        } catch (err) {
            throw err;
        }
    },
    getAllVehicleModels: async () => {
        const data = await fetchData('/fuelClaim/vehicle/AllListVehicalModel');
        dispatch({
            type: VehicleRepoActionTypes.GET_ALL_VEHICLE_MODELS,
            payload: data.map((data: any) => new VehicleModel({ id: data.id, name: data.name }))
        })
    },
    getAllVehicleTypes: async () => {
        const data = await fetchData('/fuelClaim/vehicle/AllListVehicleType');

        dispatch({
            type: VehicleRepoActionTypes.GET_ALL_VEHICLE_TYPES,
            payload: data.map((data: any) => new VehicleModel({ id: data.id, name: data.name }))
        })
    },
    getAllVehicleCapacities: async () => {
        const data = await fetchData('/fuelClaim/vehicle/AllListEngineCapacity');
        dispatch({
            type: VehicleRepoActionTypes.GET_ALL_VEHICLE_CAPACITIES,
            payload: data.map((data: any) => new EngineCapacity({ id: data.id, name: data.name }))
        })
    },
    getAllVehicleMakes: async () => {
        const data = await fetchData('/fuelClaim/vehicle/AllListVehicalMake');
        dispatch({
            type: VehicleRepoActionTypes.GET_ALL_VEHICLE_MAKES,
            payload: data.map((data: any) => new EngineMakes({ id: data.id, name: data.name }))
        })
    },
    getAllFuelGrades: async () => {
        const data = await fetchData('/fuelClaim/vehicle/AllListFuelGrade');

        dispatch({
            type: VehicleRepoActionTypes.GET_ALL_FUEL_GRADES,
            payload: data.map((data: any) => new FuelGrade({ id: data.id, name: data.name }))
        })
    },
    getAllFuelTypes: async () => {
        const data = await fetchData('/fuelClaim/vehicle/AllListFuelType');
        dispatch({
            type: VehicleRepoActionTypes.GET_ALL_FUEL_TYPES,
            payload: data.map((data: any) => new FuelType({ id: data.id, name: data.name }))
        })
    }
})

async function fetchData(url: string): Promise<any> {
    try {
        const res = await lionUserManager.authenticationManager.get(url)
        console.log({ res })

        if (res.status == 200) {
            return res.data
        } else {
            throw new Error(texts.DEFAULT_ERROR_MESSAGE)
        }
    } catch (err) {
        console.error(err);
        // throw err
    }
}

export class VehicleRepoActionTypes {
    static GET_ALL_VEHICLES = 'GET_ALL_VEHICLES';
    static GET_ALL_FUEL_TYPES = 'GET_ALL_FUEL_TYPES';
    static GET_ALL_VEHICLE_TYPES = 'GET_ALL_VEHICLE_TYPES';
    static GET_ALL_FUEL_GRADES = 'GET_ALL_FUEL_GRADES';
    static GET_ALL_VEHICLE_MODELS = 'GET_ALL_VEHICLE_MODELS';
    static GET_ALL_VEHICLE_MAKES = 'GET_ALL_VEHICLE_MAKES';
    static GET_ALL_VEHICLE_CAPACITIES = 'GET_ALL_VEHICLE_CAPACITIES';
    static GET_ALL_ELIGIBLE_FUEL_GRADES = 'GET_ALL_ELIGIBLE_FUEL_GRADES';
}