import React from "react";
import { Row, Col } from "antd";
import { FilePdfFilled } from "@ant-design/icons";
import { NavLink } from "react-router-dom";

const FileIcon = ({ name }: any) => (
	<Col
		flex='auto'
		style={{ maxWidth: 200, textAlign: "center", cursor: "pointer" }}>
		<Row align='middle' justify='center'>
			<FilePdfFilled
				style={{
					color: "var(--antd-wave-shadow-color)",
					fontSize: 100,
					paddingBottom: 10
				}}
			/>
		</Row>
		<Row align='middle' justify='center'>
			{name}
		</Row>
	</Col>
);

const DocumentView = () => {
	return (
		<Row>
			<Col>
				<NavLink target={"_blank"} to='/pdf/manual.pdf'>
					<FileIcon name='Staff Loan System User Manual' />
				</NavLink>
			</Col>
			{/* <div style={{ width: 20 }} />
			<Col>
				<NavLink target={"_blank"} to='/pdf/user_policy.pdf'>
					<FileIcon name='Staff Loan System Policy Document' />
				</NavLink>
			</Col> */}
		</Row>
	);
};

export default DocumentView;
