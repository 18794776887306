/* eslint-disable eqeqeq */
import { texts } from "../texts.config";
import { lionUserManager } from "./UserRepo";
import {
	Vehicle,
	EngineCapacity,
	FuelGrade,
	FuelType,
	VehicleType,
} from "./models/Vehicle";

const vehicle_mock = [
	{
		id: 28,
		plateNumber: "ddd",
		status: 2,
		enterDate: "2020-04-06T00:00:00.000+0000",
		fuelType: { id: 1, name: "oct-95", status: 1 },
		employee: { id: 2, emplyee_id: 2 },
		fuelGrade: { id: 1, name: "sdf", status: 5 },
		engineCapacity: { id: 1, name: "Toyota", status: 5 },
		vehicleModel: { id: 1, name: "sdf", status: "5" },
		vehicalMake: { id: 1, name: "fghj", status: 2 },
		vehicalType: { id: 1, name: "kk", status: 5 },
	},
	{
		id: 29,
		plateNumber: "xc-6932",
		status: 5,
		enterDate: "2020-04-15T00:00:00.000+0000",
		fuelType: { id: 1, name: "oct-95", status: 1 },
		employee: { id: 2, emplyee_id: 2 },
		fuelGrade: { id: 2, name: "kk", status: 5 },
		engineCapacity: { id: 1, name: "Toyota", status: 5 },
		vehicleModel: { id: 1, name: "sdf", status: "5" },
		vehicalMake: { id: 1, name: "fghj", status: 2 },
		vehicalType: { id: 1, name: "kk", status: 5 },
	},
	{
		id: 30,
		plateNumber: "jj",
		status: 4,
		enterDate: "2016-08-31T00:00:00.000+0000",
		fuelType: { id: 1, name: "oct-95", status: 1 },
		employee: { id: 2, emplyee_id: 2 },
		fuelGrade: { id: 2, name: "kk", status: 5 },
		engineCapacity: { id: 1, name: "Toyota", status: 5 },
		vehicleModel: { id: 1, name: "sdf", status: "5" },
		vehicalMake: { id: 1, name: "fghj", status: 2 },
		vehicalType: { id: 1, name: "kk", status: 5 },
	},
	{
		id: 31,
		plateNumber: "jj",
		status: 4,
		enterDate: "2016-03-31T00:00:00.000+0000",
		fuelType: { id: 1, name: "oct-95", status: 1 },
		employee: { id: 2, emplyee_id: 2 },
		fuelGrade: { id: 2, name: "kk", status: 5 },
		engineCapacity: { id: 1, name: "Toyota", status: 5 },
		vehicleModel: { id: 1, name: "sdf", status: "5" },
		vehicalMake: { id: 1, name: "fghj", status: 2 },
		vehicalType: { id: 1, name: "kk", status: 5 },
	},
	{
		id: 32,
		plateNumber: "jj",
		status: 4,
		enterDate: "2016-03-31T00:00:00.000+0000",
		fuelType: { id: 1, name: "oct-95", status: 1 },
		employee: { id: 2, emplyee_id: 2 },
		fuelGrade: { id: 2, name: "kk", status: 5 },
		engineCapacity: { id: 1, name: "Toyota", status: 5 },
		vehicleModel: { id: 1, name: "sdf", status: "5" },
		vehicalMake: { id: 1, name: "fghj", status: 2 },
		vehicalType: { id: 1, name: "kk", status: 5 },
	},
	{
		id: 33,
		plateNumber: "jj",
		status: 4,
		enterDate: "2016-01-31T00:00:00.000+0000",
		fuelType: { id: 1, name: "oct-95", status: 1 },
		employee: { id: 2, emplyee_id: 2 },
		fuelGrade: { id: 2, name: "kk", status: 5 },
		engineCapacity: { id: 1, name: "Toyota", status: 5 },
		vehicleModel: { id: 1, name: "sdf", status: "5" },
		vehicalMake: { id: 1, name: "fghj", status: 2 },
		vehicalType: { id: 1, name: "kk", status: 5 },
	},
	{
		id: 34,
		plateNumber: "jj",
		status: 4,
		enterDate: "2016-01-31T00:00:00.000+0000",
		fuelType: { id: 1, name: "oct-95", status: 1 },
		employee: { id: 2, emplyee_id: 2 },
		fuelGrade: { id: 2, name: "kk", status: 5 },
		engineCapacity: { id: 1, name: "Toyota", status: 5 },
		vehicleModel: { id: 1, name: "sdf", status: "5" },
		vehicalMake: { id: 1, name: "fghj", status: 2 },
		vehicalType: { id: 1, name: "kk", status: 5 },
	},
];

interface RepoState {
	departments: null;
	guarantors: null;
	masterData: null;
	claims: null;
	hrClaimList: null;
	hrHeadClaimList: null;
	welfareClaims: null;
	laonsForClose: null;
	aa: null;
	loanList: null;
	loanDetailsReport: null;
	userStatus: null;
    comapanies: null
}

export const Repo = (
	state: RepoState = {
		hrClaimList: null,
		loanDetailsReport: null,
		userStatus: null,
		loanList: null,
		welfareClaims: null,
		aa: null,
		hrHeadClaimList: null,
		masterData: null,
		departments: null,
		guarantors: null,
		claims: null,
		laonsForClose: null,
		comapanies: null,
	},
	action: { type: ClaimActionTypes; payload: any }
) => {
	switch (action.type) {
		case ClaimActionTypes.GET_ALL_DEPARTMENTS: {
			return { ...state, departments: action.payload };
		}
		case ClaimActionTypes.GET_ALL_COMPANIES: {
			return { ...state, comapanies: action.payload };
		}
		case ClaimActionTypes.GET_GUARANTORS: {
			return { ...state, guarantors: action.payload };
		}
		case ClaimActionTypes.GET_MasterData: {
			return { ...state, masterData: action.payload };
		}
		case ClaimActionTypes.GET_CLAIMS: {
			return { ...state, claims: action.payload };
		}
		case ClaimActionTypes.GET_HR_CLAIMS: {
			return { ...state, hrClaimList: action.payload };
		}
		case ClaimActionTypes.GET_HR_HEAD_CLAIMS: {
			return { ...state, hrHeadClaimList: action.payload };
		}
		case ClaimActionTypes.GET_WELFARE_CLAIMS: {
			return { ...state, welfareClaims: action.payload };
		}
		case ClaimActionTypes.GET_LOANS_FOR_CLOSE: {
			return { ...state, laonsForClose: action.payload };
		}

		case ClaimActionTypes.LOAN_SETTLEMENT: {
			return { ...state, aa: action.payload };
		}
		case ClaimActionTypes.GET_LOANS_LIST: {
			return { ...state, loanList: action.payload };
		}
		case ClaimActionTypes.LOAN_DETAILS_REPORT_DATA: {
			return { ...state, loanDetailsReport: action.payload };
		}
		case ClaimActionTypes.USER_STATUS: {
			return { ...state, userStatus: action.payload };
		}

		default:
			return state;
	}
};

export const ClaimRepoActions = (dispatch: any) => ({
    getCompanies: async () => {
        try {
            // /user/comapanies
            const res = await lionUserManager.authenticationManager.get('/user/comapanies');
 
            if (res.status == 200) {
                dispatch({
                    type: ClaimActionTypes.GET_ALL_COMPANIES,
                    payload: res.data
                });
            } else {
                throw new Error(texts.DEFAULT_ERROR_MESSAGE)
            }
        } catch (err) {
            throw err;
        }
    },
    
	getDepartment: async () => {
		try {
			//https://lionapi.eimsky.com/staffLoan/StaffLoan/getDepartment
			const res = await lionUserManager.authenticationManager.get(
				"/staffLoan/StaffLoan/getDepartment"
			);

			if (res.status == 200) {
				dispatch({
					type: ClaimActionTypes.GET_ALL_DEPARTMENTS,
					payload: res.data,
				});
			} else {
				throw new Error(texts.DEFAULT_ERROR_MESSAGE);
			}
		} catch (err) {
			throw err;
		}
	},

	getGuarantor: async (departmentId: any) => {
		try {
			const res = await lionUserManager.authenticationManager.get(
				"/staffLoan/StaffLoan/getDepartmentWiseUser/" + departmentId
			);

			if (res.status == 200) {
				dispatch({
					type: ClaimActionTypes.GET_GUARANTORS,
					payload: res.data,
				});
			} else {
				throw new Error(texts.DEFAULT_ERROR_MESSAGE);
			}
		} catch (err) {
			throw err;
		}
	},

	getMasterData: async () => {
		try {
			const res = await lionUserManager.authenticationManager.get(
				"/staffLoan/StaffLoan/MasterData"
			);

			if (res.status == 200) {
				dispatch({
					type: ClaimActionTypes.GET_MasterData,
					payload: res.data,
				});
			} else {
				throw new Error(texts.DEFAULT_ERROR_MESSAGE);
			}
		} catch (err) {
			throw err;
		}
	},

	applyLoan: async (data: any) => {
		try {
			const res = await lionUserManager.authenticationManager.post(
				"/staffLoan/StaffLoan/Create",
				data
			);
			if (res.status == 200) {
				return true;
			} else {
				return false;
			}
		} catch (err) {
			return false;
		}
	},

	getAllClaims: async () => {
		try {
			const res = await lionUserManager.authenticationManager.get(
				"/staffLoan/StaffLoan/getClaimsForFirstApprover/" +
					lionUserManager.user.data.USER_ID
			);
			if (res.status == 200) {
				dispatch({
					type: ClaimActionTypes.GET_CLAIMS,
					payload: res.data,
				});
			} else {
				throw new Error(texts.DEFAULT_ERROR_MESSAGE);
			}
		} catch (err) {
			throw err;
		}
	},

	gurantorApproval: async (data: any) => {
		try {
			const res = await lionUserManager.authenticationManager.post(
				"/staffLoan/StaffLoan/approveClaimsFromFirstApprover",
				data
			);
			if (res.status == 201) {
				return true;
			} else {
				return false;
			}
		} catch (err) {
			return false;
		}
	},

	getHrClaimList: async (id: any) => {
		try {
			const res = await lionUserManager.authenticationManager.get(
				"/staffLoan/StaffLoan/getClaimsForSecondApprover/0"
			);

			if (res.status == 200) {
				if (res.status == 200) {
					dispatch({
						type: ClaimActionTypes.GET_HR_CLAIMS,
						payload: res.data,
					});
				} else {
					throw new Error(texts.DEFAULT_ERROR_MESSAGE);
				}
			} else {
				return false;
			}
		} catch (err) {
			return false;
		}
	},

	sendToHrHead: async (data: any) => {
		try {
			const res = await lionUserManager.authenticationManager.post(
				"/staffLoan/StaffLoan/approveClaimsFromSecondApprove",
				data
			);

			if (res.status == 200) {
				return true;
			} else {
				return false;
			}
		} catch (err) {
			return false;
		}
	},

	sendToWelfare: async (data: any) => {
		try {
			const res = await lionUserManager.authenticationManager.post(
				"/staffLoan/StaffLoan/approveClaimsFormThirdApprove",
				data
			);

			if (res.status == 200) {
				return true;
			} else {
				return false;
			}
		} catch (err) {
			return false;
		}
	},

	welfareApproval: async (data: any) => {
		try {
			const res = await lionUserManager.authenticationManager.post(
				"/staffLoan/StaffLoan/approveClaimsFormFouthApprove ",
				data
			);

			if (res.status == 200) {
				return true;
			} else {
				return false;
			}
		} catch (err) {
			return false;
		}
	},

	getClaimListOfHrHead: async (id: any) => {
		try {
			const res = await lionUserManager.authenticationManager.get(
				"/staffLoan/StaffLoan/getClaimsForThirdApprover"
			);
			if (res.status == 200) {
				if (res.status == 200) {
					dispatch({
						type: ClaimActionTypes.GET_HR_HEAD_CLAIMS,
						payload: res.data,
					});
				} else {
					throw new Error(texts.DEFAULT_ERROR_MESSAGE);
				}
			} else {
				return false;
			}
		} catch (err) {
			return false;
		}
	},

	getAllWelfareClaims: async (id: any) => {
		try {
			const res = await lionUserManager.authenticationManager.get(
				"/staffLoan/StaffLoan/getClaimsForFourthApprover"
			);

			if (res.status == 200) {
				if (res.status == 200) {
					dispatch({
						type: ClaimActionTypes.GET_WELFARE_CLAIMS,
						payload: res.data,
					});
				} else {
					throw new Error(texts.DEFAULT_ERROR_MESSAGE);
				}
			} else {
				return false;
			}
		} catch (err) {
			return false;
		}
	},

	getLoanForClose: async (id: any) => {
		try {
			const res = await lionUserManager.authenticationManager.get(
				"/staffLoan/StaffLoan/getClaimsForFivethApprover"
			);
			if (res.status == 200) {
				if (res.status == 200) {
					dispatch({
						type: ClaimActionTypes.GET_LOANS_FOR_CLOSE,
						payload: res.data,
					});
				} else {
					throw new Error(texts.DEFAULT_ERROR_MESSAGE);
				}
			} else {
				return false;
			}
		} catch (err) {
			return false;
		}
	},

	submitChequeNumber: async (data: any) => {
		try {
			const res = await lionUserManager.authenticationManager.post(
				"/staffLoan/StaffLoan/approveClaimsFormFivethApprove",
				data
			);

			if (res.status == 200) {
				return true;
			} else {
				return false;
			}
		} catch (err) {
			return false;
		}
	},

	approveLoanSettlement: async (data: any, id: any) => {
		try {
			const res = await lionUserManager.authenticationManager.post(
				"/staffLoan/LoanSettlement/ApproveSettlement/" + id,
				data
			);
			if (res.status == 200) {
				return true;
			} else {
				return false;
			}
		} catch (err) {
			return false;
		}
	},

	getLoanForSettelment: async (id: any) => {
		try {
			const res = await lionUserManager.authenticationManager.get(
				"/staffLoan/LoanSettlement/getAllLoanSettlement"
			);
			if (res.status == 200) {
				if (res.status == 200) {
					dispatch({
						type: ClaimActionTypes.LOAN_SETTLEMENT,
						payload: res.data,
					});
				} else {
					throw new Error(texts.DEFAULT_ERROR_MESSAGE);
				}
			} else {
				return false;
			}
		} catch (err) {
			return false;
		}
	},

	getLoanDetailsReport: async (data: any) => {
		try {
			const res = await lionUserManager.authenticationManager.post(
				"/staffLoan/StaffLoan/getAllLoans",
				data
			);
            if (res.status == 200) {
                dispatch({
                    type: ClaimActionTypes.LOAN_DETAILS_REPORT_DATA,
                    payload: res.data,
                });
            } else {
                throw new Error(texts.DEFAULT_ERROR_MESSAGE);
            }
		} catch (err) {
			return false;
		}
	},

	getLoanDetailsReportByDepartment: async (depId: any, form : any) => {
		try {
			const res = await lionUserManager.authenticationManager.post(
				`/staffLoan/StaffLoan/getCompanyWiseUserLoan/${depId}`, 
                form
			);

            console.log({getLoanDetailsReportByDepartment: res})

            if (res.status == 200) {
                dispatch({
                    type: ClaimActionTypes.LOAN_DETAILS_REPORT_DATA,
                    payload: res.data,
                });
            } else {
                throw new Error(texts.DEFAULT_ERROR_MESSAGE);
            }
		} catch (err) {
			return false;
		}
	},

	getCurrentLoanList: async (data: any) => {
		try {
			const res = await lionUserManager.authenticationManager.post(
				"/staffLoan/StaffLoan/getAllLoans",
				data
			);
			if (res.status == 200) {
				if (res.status == 200) {
					dispatch({
						type: ClaimActionTypes.GET_LOANS_LIST,
						payload: res.data,
					});
				} else {
					throw new Error(texts.DEFAULT_ERROR_MESSAGE);
				}
			} else {
				return false;
			}
		} catch (err) {
			return false;
		}
	},

	getUserStatus: async (data: any) => {
		try {
			const res = await lionUserManager.authenticationManager.get(
				"/staffLoan/StaffLoan/activeLoan/" + lionUserManager.user.data.USER_ID
			);

			if (res.status == 200) {
				if (res.status == 200) {
					dispatch({
						type: ClaimActionTypes.USER_STATUS,
						payload: res.data,
					});
				} else {
					throw new Error(texts.DEFAULT_ERROR_MESSAGE);
				}
			} else {
				return false;
			}
		} catch (err) {
			return false;
		}
	},
	getUploadedDocuments: async () => {
		console.log("sasasasasa");
		try {
			const res = await lionUserManager.authenticationManager.get(
				"/clubMembership/document/getDoc"
			);
			console.log(res);
			if (res.status == 200) {
				dispatch({
					type: ClaimActionTypes.DOCUMENTS,
					payload: res.data,
				});
			} else {
				throw new Error(texts.DEFAULT_ERROR_MESSAGE);
			}
		} catch (err) {
			throw err;
		}
	},
});

export class ClaimActionTypes {
	static GET_ALL_DEPARTMENTS = "GET_ALL_DEPARTMENTS";
	static GET_ALL_COMPANIES = "GET_ALL_COMPANIES";
	static GET_GUARANTORS = "GET_GUARANTORS";
	static GET_MasterData = "GET_MasterData";
	static GET_CLAIMS = "GET_CLAIMS";
	static GET_HR_CLAIMS = "GET_HR_CLAIMS";
	static GET_HR_HEAD_CLAIMS = "GET_HR_HEAD_CLAIMS";
	static GET_WELFARE_CLAIMS = "GET_WELFARE_CLAIMS";
	static GET_LOANS_FOR_CLOSE = "GET_LOANS_FOR_CLOSE";
	static GET_LOANS_LIST = "GET_LOANS_LIST";
	static LOAN_SETTLEMENT = "LOAN_SETTLEMENT";
	static LOAN_DETAILS_REPORT_DATA = "LOAN_DETAILS_REPORT_DATA";
	static USER_STATUS = "USER_STATUS";
	static DOCUMENTS = "DOCUMENTS";
}
