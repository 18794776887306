import { Repo as UserRepo } from './UserRepo';
import { Repo as DashboardRepo } from './DashboardRepo';
import { Repo as ClaimRepo } from './ClaimRepo';
import { Repo as VehicleRepo } from './VehicleRepo';
import { combineReducers, applyMiddleware, createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import logger from 'redux-logger';
import {
    createStateSyncMiddleware,
    Config as ReactStateSyncConfig,
    withReduxStateSync,
    initStateWithPrevTab
} from 'redux-state-sync';


const config: ReactStateSyncConfig = {
    // blacklist: ["RESET"]
}

const repos = {
    UserRepo,
    DashboardRepo,
    ClaimRepo,
    VehicleRepo
}

const persistConfig = {
    key: 'root',
    storage,
}
// const persistedReducer = persistReducer(persistConfig, combineReducers(repos));

const createStoreWithMiddleware = applyMiddleware(createStateSyncMiddleware(config))(createStore);
const store = createStoreWithMiddleware(withReduxStateSync(combineReducers(repos) as { state: number } | any));

// const persistor = persistStore(store)

export const getStore = () => {
    initStateWithPrevTab(store)
    return store;
}