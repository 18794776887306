import { texts } from "../texts.config";
import { lionUserManager } from './UserRepo'

export const Repo = (state = {guarantors : null,  departments: null, summaryData: null , userHistory : null , userClaimDetails: null }, action: { type: DashboardActionTypes, payload: any }) => {
    switch (action.type) {
        
        case DashboardActionTypes.GET_USER_SUMMARY: {
            return { ...state, summaryData: action.payload }
        }
        case DashboardActionTypes.GET_USER_CLAIM_DETAILS: {
            return { ...state, userClaimDetails: action.payload }
        }
        case DashboardActionTypes.GET_ALL_DEPARTMENTS: {
            return { ...state, departments: action.payload }
        } 
        case DashboardActionTypes.GET_GUARANTORS: {
            return { ...state, guarantors : action.payload }
        } 
        default: return state;
    }
}

export const DashboardActions = (dispatch: any) => ({
 

    getSummaryData: async () => {
        try {
            //GetClaimCountEmployeeWise 
            const res = await lionUserManager.authenticationManager.get("/staffLoan/dashboard/get/" + lionUserManager.user.data.USER_ID  );
            if (res.status == 200) {  
                 dispatch({
                    type: DashboardActionTypes.GET_USER_SUMMARY,
                    payload: res.data
                });
            } else {
                throw new Error(texts.DEFAULT_ERROR_MESSAGE)
            }
        } catch (err) {
            throw err;
        }
    },

    getUserClaimData: async () => {
        try {
            //GetClaimCountEmployeeWise 
            const res = await lionUserManager.authenticationManager.get("/staffLoan/StaffLoan/getLoanHistory/"+lionUserManager.user.data.USER_ID  );
 
            if (res.status == 200) {  
				  
                dispatch({
                    type: DashboardActionTypes.GET_USER_CLAIM_DETAILS,
                    payload: res.data
                });
            } else {
                throw new Error(texts.DEFAULT_ERROR_MESSAGE)
            }
        } catch (err) {
            throw err;
        }
    },

    getDepartment: async () => {
        try {
           
            //https://lionapi.eimsky.com/staffLoan/StaffLoan/getDepartment
               const res = await lionUserManager.authenticationManager.get('/staffLoan/StaffLoan/getDepartment');
            if (res.status == 200) {
                dispatch({
                    type: DashboardActionTypes.GET_ALL_DEPARTMENTS,
                    payload: res.data
                });
            } else {
                throw new Error(texts.DEFAULT_ERROR_MESSAGE)
            }
        } catch (err) {
            throw err;
        }
    },

    getGuarantor: async (departmentId : any) => {
        try {
 
               const res = await lionUserManager.authenticationManager.get('/staffLoan/StaffLoan/getDepartmentWiseUser/' + departmentId);
 
            if (res.status == 200) {
                dispatch({
                     type: DashboardActionTypes.GET_GUARANTORS,
                      payload: res.data
                });
            } else {
                throw new Error(texts.DEFAULT_ERROR_MESSAGE)
            }
        } catch (err) {
            throw err;
        }
    },


    sendToApproval: async (id : any) => {
        try {
            
               const res = await lionUserManager.authenticationManager.post('/staffLoan/StaffLoan/SendToApprove/' + id , {});
 
            if (res.status == 200) {
                 return true;
            } else {
                return false;
                throw new Error(texts.DEFAULT_ERROR_MESSAGE)
            }
        } catch (err) {
            return false;
            throw err;
        }
    },

    deleteLoanRequest: async (id : any) => {
        try {
            
               const res = await lionUserManager.authenticationManager.post('/staffLoan/StaffLoan/LoanDelete/' + id , {});
                
            if (res.status == 200) {
                return true;
            } else {
                return false;
                throw new Error(texts.DEFAULT_ERROR_MESSAGE)
            }
        } catch (err) {
            return false;
            throw err;
        }
    },

    resubmitClaim  : async (id : any) => {
        try {
             var data = { 
                "LoanId": id
             }
            
               const res = await lionUserManager.authenticationManager.post('/staffLoan/StaffLoan/ResubmitLoan' , data);
 
            if (res.status == 201) {
                return true;
            } else {
                return false;
                throw new Error(texts.DEFAULT_ERROR_MESSAGE)
            }
        } catch (err) {
            return false;
            throw err;
        }
    },


    editLoan  : async (data : any , id : any) => {
        try {
             
               const res = await lionUserManager.authenticationManager.post('/staffLoan/StaffLoan/UpdateDrafModeLoan/' + id , data);
 
            if (res.status == 200) {
                return true;
            } else {
                return false;
                throw new Error(texts.DEFAULT_ERROR_MESSAGE)
            }
        } catch (err) {
            return false;
            throw err;
        }
    },


    settleLoan: async (id : any) => {
        try {
            
               const res = await lionUserManager.authenticationManager.post('/staffLoan/LoanSettlement/CreateSettlement', id);
             if (res.status == 200) {
                 return true;
            } else {
                return false;
                throw new Error(texts.DEFAULT_ERROR_MESSAGE)
            }
        } catch (err) {
            return false;
            throw err;
        }
    },
 
 
})

export class DashboardActionTypes { 
    static GET_USER_SUMMARY = 'GET_USER_SUMMARY'; 
    static GET_USER_CLAIM_DETAILS = 'GET_USER_CLAIM_DETAILS'; 
    static GET_ALL_DEPARTMENTS = 'GET_ALL_DEPARTMENTS';
    static GET_GUARANTORS = 'GET_GUARANTORS';
}