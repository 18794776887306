import React, { FunctionComponent } from 'react';
import { Table, Spin, Badge, Tag } from 'antd';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import { ClaimRepoActions } from '../repos/ClaimRepo';
// import logo from '../logo.png' 
import './style.css';
import { FundViewOutlined } from '@ant-design/icons';

const { Column } = Table;

const state_value = [ "DRAFT", "PENDING" ,"APPROVED" ,"REJECTED" , "DISABLED"  ]
 
const color = ["#0d52d0" , "#faad14","#52c41a" , "#d9363e" ,"#0d52d0"]

class ViewAllClaims extends React.Component<any, any> {
    constructor(props: any) {
        super(props);

        this.state = {
            claims: [],
            loading: false,
            visibleView: false,
        };
    }

    componentDidMount() {

        this._getData()
    }

    async  _getData() {
        this.setState({ loading: true })
        await this.props.getUserHistory();

        this.setState({ loading: false, claims: this.props.ClaimRepo.userSummary })


    }

    render() {

        return (
            <div >
                <Spin size="small" tip="Data Loading" spinning={this.state.loading} delay={500}>
                    <div style={{ opacity: this.state.loading ? 0.8 : 1 }}>

                        {this.state.claims && <Table style={{ marginTop: 26, padding: 5 }} dataSource={this.state.claims.reverse()}>
                            <Column title="No" key="index" render={(value, item, index) => (index + 1)} />
                            {/* <Column width="100" title="Created Date"   key="createdOn"  render={(value, item, index) => (  value.createdOn.split("T")[0]  )}/> */}
                            <Column title="Amount " dataIndex="totalAmount" key="totalAmount" render={(value, item, index) => ("Rs. " + (Math.round(value * 100) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))} />
                            <Column width="100" title="Status" key="status" render={(value, item: any, index) => (
                                <Badge count={state_value[item.status]} style={{ backgroundColor: color[item.status] }} />
                                // value.status != 0 ?
                                //     <div>
                                //         <Badge count={" DISCUSSED"} style={{ backgroundColor: "#52c41a" }} />

                                //     </div> : <div>

                                //         <Badge count={"PENDING"} style={{ backgroundColor: "#faad14" }} />
                                //     </div>
                            )} />
                            <Column
                                title="Action"
                                key="index"
                                render={(value, item, index) => (
                                    <div>
                                        <Link to={`view-claim/${value.id}`}>
                                            <span>
                                                <a > <Tag color="#875ec4"> View</Tag> </a>
                                            </span>
                                        </Link>
                                    </div>
                                )}
                            />
                        </Table>}

                    </div>
                </Spin>
            </div>
        );
    }
}

const WrappedViewAllClaims = ViewAllClaims;
const withClaimRepo = connect(((state: any) => state), ClaimRepoActions)(WrappedViewAllClaims);
export default withClaimRepo;