import React from "react";
import { forwardRef } from "react";
import { Col, PageHeader, Row, Table } from "antd";
import { getStatusByCode } from "../statusMapper";

type PrintableLoanDetailReportProps = {
	hidden: boolean;
	dataSource: any[];
};

export const PrintableLoanDetailReport = forwardRef<
	HTMLDivElement,
	PrintableLoanDetailReportProps
>((props, ref) => {
	return (
		<div style={props.hidden ? { display: "none" } : {}}>
			<div ref={ref}>
				<Row>
					<PageHeader
						title={"LION BREWERY (CEYLON) PLC"}
						subTitle='Loan Details Report'
					/>
				</Row>
				<Row gutter={10} style={{ margin: "10px 15px" }}>
					<Col flex='auto'>
						<Table
							style={{ marginTop: 26, padding: 5 }}
							pagination={false}
							dataSource={props.dataSource}>
							<Table.Column
								title='Loan Id'
								dataIndex='id'
								render={(value, item, index) => value}
							/>
							<Table.Column width='100' title='Epf No' dataIndex='epfNo' key='epfNo' />
							<Table.Column
								width='100'
								title='Employee Name'
								dataIndex='name'
								key='name'
							/>
							<Table.Column
								width='100'
								title='Loan Amount'
								dataIndex='loanAmount'
								render={(value, item, index) =>
									"Rs. " +
									(Math.round(value * 100) / 100)
										.toFixed(2)
										.toString()
										.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
								}
							/>
							<Table.Column
								width='100'
								title='Installment Amount'
								dataIndex='installmentAmount'
								render={(value, item, index) =>
									"Rs. " +
									(Math.round(value * 100) / 100)
										.toFixed(2)
										.toString()
										.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
								}
							/>
							<Table.Column
								title='Interest Rate'
								dataIndex='interestRate'
								key='interestRate'
							/>
							<Table.Column
								title='Status'
								dataIndex='processingStatus'
								render={(value, item, index) => getStatusByCode(parseInt(value))?.title}
							/>
						</Table>
					</Col>
				</Row>
			</div>
		</div>
	);
});
