import React, { FunctionComponent, Component, createRef, RefObject } from 'react';
import moment, { Moment } from 'moment';
import { texts } from "../texts.config";
import { fromEvent, Subject, OperatorFunction, timer, Subscription } from 'rxjs'
import { debounceTime, throttleTime } from 'rxjs/operators'
import { connect } from 'react-redux';
import { Upload, Alert, Descriptions, Steps, message, List, Spin, Form, Input, InputNumber, Button, Switch, Select, Col, Modal, DatePicker, Row, Avatar, Typography, Table, Tag, Dropdown } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { FileAddOutlined, DeleteOutlined, InboxOutlined, CodeSandboxCircleFilled, FileSyncOutlined } from '@ant-design/icons';
import { UploaderState, FileDataMapper, MultipleFileUploader, defaultImageExtensions } from '../components';
import { lionUserManager, userManager } from '../repos/UserRepo'
import { ClaimRepoActions } from '../repos/ClaimRepo';
import { VehicleRepoActions } from '../repos/VehicleRepo';
import { Claim } from '../repos/models/Claim';
import { Vehicle, FuelGrade } from '../repos/models/Vehicle';
import _ from 'lodash'
import './style.css';
import { RcFile } from 'antd/lib/upload';
import { number } from 'prop-types';
import VehicleCard from '../components/Cards/VehicleCard';
import { FromEventTarget } from 'rxjs/internal/observable/fromEvent';
import { debounce } from '@antv/util';
import { start } from 'repl';
import { RangeValue } from 'rc-picker/lib/interface';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { imgBaseUrl } from '../api.config';

// const { Paragraph, Text } = Typography;
// const { Column, ColumnGroup } = Table;

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};

const validateMessages = {
    required: 'This field is required!',
    types: {
        email: 'Not a validate email!',
        number: 'Not a validate number!',
    },
    number: {
        range: 'Must be between ${min} and ${max}',
    },
};

// class TableClaimItem extends Claim {
//     key: number;
//     constructor(claim: Claim, key: number) {
//         super(claim)
//         this.key = key
//     }
// }

// interface CreateClaimState {
//     loading: boolean,
//     claimList: Array<TableClaimItem>
//     isModalVisible: boolean
//     totalValue: number
//     today: Moment
//     currentVehicle: Vehicle | null
//     totalAmount: number
//     meterStartValue: number
//     meterEndValue: number
//     startDate: string
//     endDate: string
// }

// class EditClaim extends React.Component<any, CreateClaimState> {
//     formRef: RefObject<FormInstance> = React.createRef<FormInstance>();
//     defaultStateObject: CreateClaimState = {
//         loading: false,
//         claimList: [],
//         isModalVisible: false,
//         totalValue: 0,
//         currentVehicle: null,
//         totalAmount: 0,
//         today: moment(),
//         meterStartValue: 0,
//         meterEndValue: 0,
//         startDate: moment().format('YYYY-MM-DD'),
//         endDate: moment().format('YYYY-MM-DD')
//     }

//     constructor(props: any) {
//         super(props);
//         this.onDone = this.onDone.bind(this)
//         this._getData = this._getData.bind(this)
//         this.onVehicleChange = this.onVehicleChange.bind(this)
//         this._onEndDateChange = this._onEndDateChange.bind(this)
//         this._onEndMeterChange = this._onEndMeterChange.bind(this)
//         this._onStartDateChange = this._onStartDateChange.bind(this)
//         this._onStartMeterChange = this._onStartMeterChange.bind(this)
//         this.removeClaimFromList = this.removeClaimFromList.bind(this)

//         this.state = this.defaultStateObject;
//     }

//     componentDidMount() {
//         this._getData()
//     }

//     async  _getData() {
//         console.log(this.props)
//         try {

//             // await this.props.getAllClaimDetailsById(this.props.match.params.id.split("_")[0]);
//             // console.log('current', this.props.ClaimRepo.allClaimDetails.find((data: any) => data.id == this.props.match.params.id.split("_")[0]))
//             await this.props.getAllEligibleFuelGrades()
//             await this.props.getAllVehicles()
//             if (this.props.VehicleRepo.allVehicles.length > 0)
//                 this.setState({
//                     currentVehicle: this.props.VehicleRepo.allVehicles[0]
//                 })
//             console.log(this.props)
//         } catch (err) {
//             message.error(err.message)
//         }
//     }

//     async submit_form() {
//         var hide;
//         try {
//             if (this.formRef.current)
//                 await this.formRef.current.validateFields()

//             try {

//                 const _form = new FormData()

//                 var vehicleId: number;

//                 if (this.state.currentVehicle != null && this.state.currentVehicle.id != null) {
//                     vehicleId = this.state.currentVehicle.id;
//                 } else throw new Error("Please select a Vehicle")

//                 if (this.state.claimList.length < 1) {
//                     throw new Error("Please add Claims")
//                 }

//                 if (this.state.meterStartValue > this.state.meterEndValue) {
//                     throw new Error("The starting value of the meter reading should be less than the final value")
//                 }

//                 hide = message.loading('Action in progress..', 0);

//                 const formData = JSON.stringify({
//                     "employeeId": lionUserManager.user.data.User.id,
//                     "totalAmount": this.state.totalAmount,
//                     "meterReadingStartDate": this.state.startDate,
//                     "meterReadingEndDate": this.state.endDate,
//                     "meterReadingStart": this.state.meterStartValue,
//                     "meterReadingEnd": this.state.meterEndValue,
//                     "vehicalDetails": {
//                         "id": vehicleId
//                     },
//                     "fuelBill": this.state.claimList.map((claim: Claim) => ({
//                         "employeeId": lionUserManager.user.data.User.id,
//                         "amount": claim.amount,
//                         "pumpDate": claim.pumpDate,
//                         "fuelGrade": {
//                             "id": claim.fuelGrade.id
//                         },
//                         "meterReading": claim.meterReading,
//                         "liters": claim.liters.toString(),
//                         "exceededEngin": claim.exceededEngin,
//                         "billDocuments": claim.files.map((file: RcFile) => ({
//                             "fileName": file.name,
//                             "documentType": 1
//                         }))
//                     }))
//                 })

//                 _form.append("fuelClaim", formData)

//                 this.state.claimList.forEach((claim: Claim) => claim.files.forEach((file: RcFile) => _form.append("files", file)))

//                 var response = await lionUserManager.authenticationManager.post('/fuelClaim/FuelClaim/Create', _form)

//                 if (response.status == 200) {
//                     console.dir(await lionUserManager.authenticationManager.get('/fuelClaim/FuelClaim/AllPendingClaim'))
//                     message.success(`Successfully Created`);
//                     this.setState({
//                         claimList: [],
//                         totalAmount: 0,
//                         totalValue: 0,
//                         meterStartValue: 0,
//                         meterEndValue: 0
//                     })
//                     this.formRef.current && this.formRef.current.resetFields()
//                 } else throw new Error(response.message)


//             } catch (err) {
//                 message.error(err.message)
//             } finally {
//                 hide && hide()
//             }
//         } catch (err) {

//         }
//     }

//     removeClaimFromList(index: number) {
//         this.setState((prevState: CreateClaimState, props) => {
//             const claim: TableClaimItem = prevState.claimList[index];
//             prevState.claimList.splice(index, 1)
//             prevState.totalValue = prevState.totalValue - claim.meterReading;
//             prevState.totalAmount = prevState.totalAmount - claim.amount;

//             return prevState;
//         })
//     }

//     async onDone(claim: Claim) {
//         console.log(claim)
//         this.setState((prevState: CreateClaimState, props) => {
//             prevState.isModalVisible = false;
//             prevState.claimList.push(new TableClaimItem(claim, this.state.claimList.length + 1));
//             prevState.totalValue = prevState.totalValue + claim.meterReading
//             prevState.totalAmount = prevState.totalAmount + claim.amount;

//             return prevState;
//         })
//         return false
//     }

//     onVehicleChange(vehicle: Vehicle) {
//         console.log({ vehicle })
//         this.setState({
//             currentVehicle: vehicle
//         })
//     }

//     _onStartDateChange(props: RangeValue<Moment>) {
//         const startDate = (props && props[0]) && props[0].format('YYYY-DD-MM')
//         const endDate = (props && props[0]) && props[0].format('YYYY-DD-MM')
//         if (startDate && endDate)
//             this.setState({
//                 startDate: startDate,
//                 endDate: endDate
//             })
//     }

//     _onEndDateChange(value: any | null, dateString: string) {
//         this.setState({
//             endDate: dateString
//         })
//     }

//     _onStartMeterChange(value: any) {
//         this.setState({
//             meterStartValue: value
//         })
//     }

//     _onEndMeterChange(value: any) {
//         this.setState({
//             meterEndValue: value
//         })
//     }

//     render() {
//         return (
//             <Form ref={this.formRef}
//                 labelCol={{ span: 8 }}
//                 wrapperCol={{ span: 16 }}

//             >

//                 {/* {JSON.stringify} */}
//                 {/* {this.state.isModalVisible && this.state.currentVehicle && <EditClaimForm
//                     title="Create Claim"
//                     vehicle={this.state.currentVehicle}
//                     defaultFuelGrade={this.state.currentVehicle ? this.state.currentVehicle.fuelGrade : this.props.VehicleRepo.allEligibleFuelGrades[0]}
//                     fuelGrades={this.props.VehicleRepo.allEligibleFuelGrades}
//                     isVisible={this.props.VehicleRepo.allEligibleFuelGrades.length > 0 && this.state.isModalVisible}
//                     onCancel={() => this.setState({ isModalVisible: false })}
//                     onDone={this.onDone} />} */}

//                 <Row>
//                     <Col span={15}>
//                         <Row>
//                             <Text style={{ fontSize: 18 }} strong>{`${lionUserManager.user.data.User.firstName} ${lionUserManager.user.data.User.lastName}`}</Text>
//                         </Row>
//                         <Row>
//                             <Text style={{ fontSize: 18 }} >{lionUserManager.user.data.User.designationName}</Text>
//                         </Row>
//                     </Col>
//                     <Col span={9}>
//                         {(this.props.VehicleRepo.allVehicles.length > 0 && this.state.currentVehicle != null) && <VehicleSelector onChange={this.onVehicleChange} default={this.state.currentVehicle} data={this.props.VehicleRepo.allVehicles} />}
//                     </Col>
//                 </Row>
//                 <Row gutter={10} style={{ marginTop: 25 }}>
//                     <Col flex={1} span={12}>
//                         <Form.Item
//                             name="meter-reading-start"
//                             label="Meter Reading Start"
//                             rules={[
//                                 {
//                                     required: true,
//                                     message: 'This field is required!',
//                                 },
//                                 {
//                                     type: 'number',
//                                     message: 'Meter reading is not valid',
//                                 },
//                             ]}>
//                             <InputNumber min={0} onChange={this._onStartMeterChange} style={{ width: '100%' }} />
//                         </Form.Item>
//                     </Col>
//                     <Col flex={1}>
//                         <Form.Item
//                             name="meter-reading-end"
//                             label="Meter Reading End"
//                             rules={[
//                                 {
//                                     required: true,
//                                     message: 'This field is required!',
//                                 },
//                                 {
//                                     type: 'number',
//                                     message: 'Meter reading is not valid',
//                                 },
//                             ]}>
//                             <InputNumber min={0} onChange={this._onEndMeterChange} style={{ width: '100%' }} />
//                         </Form.Item>
//                     </Col>
//                     <Col span={12}>
//                         <Form.Item
//                             name="date-picker-start"
//                             label="Date Range"
//                             rules={[
//                                 {
//                                     required: true,
//                                     message: 'This field is required!',
//                                 }
//                             ]}>
//                             <DatePicker.RangePicker onChange={this._onStartDateChange} style={{ width: '100%' }} />
//                         </Form.Item>
//                     </Col>
//                 </Row>

//                 <Button onClick={() => this.setState({ isModalVisible: true })}>
//                     <FileAddOutlined /> Add claim
//                 </Button>

//                 <Table dataSource={[...this.state.claimList]} style={{ marginTop: 30, height: "330px" }}>
//                     <Column title="Index" dataIndex="key" />
//                     <Column title="Pump Date" dataIndex="pumpDate" />
//                     <Column title="Meter Reading" dataIndex="amount" />
//                     <Column
//                         title="Actions"
//                         dataIndex="key"
//                         key="key"
//                         render={(value: any, record: any, index: number) => (
//                             <span>
//                                 <DeleteOutlined onClick={() => this.removeClaimFromList(index)} />
//                             </span>
//                         )}
//                     />
//                 </Table>
//                 <Descriptions >
//                     <Descriptions.Item label="Total Amount">{this.state.totalAmount}</Descriptions.Item>
//                     <Descriptions.Item label="Total Value">{this.state.totalValue}KM</Descriptions.Item>
//                     <Descriptions.Item label="Date">{this.state.today.format('YYYY MMMM DD')}</Descriptions.Item>
//                     <Descriptions.Item>
//                         <Button type="primary" onClick={() => this.submit_form()}>Submit Claim</Button>
//                     </Descriptions.Item>
//                 </Descriptions>
//             </Form>
//         );
//     }
// }

interface EditClaimFormState {
    fuelGrade: { id: number }
    literAmount: number
    attachments: UploaderState | null
    pumpDate: string
    meterReading: number
    amount: number
    errors?: FormError[]
    externalAttachments: { id: number, name: string, type: number }[]
}

interface EditClaimFormProps {
    attachments: { id: number, name: string, type: number }[]
    vehicle: { id: number }
    fuelGrades: FuelGrade[]
    defaultFuelGrade: { id: number }
    title: string
    isVisible: boolean
    pumpDate: string,
    onCancel: () => void
    onDone: (data: any) => Promise<boolean>
    literAmount: number,
    amount: number,
    meterReading: number
}

const fuelMinMaxErrorMessage = "Be sure to provide a valid invoice description."
const engineCapacityErrorMessage = "Be sure to provide valid pump details."
// const defaultState: EditClaimFormState = {
//     literAmount: 0,
//     amount: 0,
//     meterReading: 0,
//     pumpDate: "",
//     attachments: null,
//     fuelGrade: new FuelGrade({ id: -1, name: 'NULL' }),
//     errors: [
//         // { message: fuelMinMaxErrorMessage, source: 'FUEL_MAX_MIN' },
//         // { message: engineCapacityErrorMessage, source: 'ENGINE_CAPACITY' }
//     ]
// }

interface FormError {
    source: string,
    message: string
}

interface CriticalValidationsProps {
    VehicleEngineCapacity: boolean
    FuelGradeMinMaxValidator: boolean
}

class CriticalValidations {
    value: CriticalValidationsProps
    constructor() {
        this.value = {
            VehicleEngineCapacity: false,
            FuelGradeMinMaxValidator: false
        }
    }

    isValid() {
        return this.value.VehicleEngineCapacity && this.value.FuelGradeMinMaxValidator
    }
}

export class EditClaimForm extends React.Component<EditClaimFormProps, EditClaimFormState>{
    formRef: RefObject<FormInstance> = React.createRef<FormInstance>();
    testField: RefObject<InputNumber> = React.createRef()

    VehicleEngineCapacityValidator = new Subject<any>()
    FuelGradeMinMaxValidator = new Subject<any>()

    VehicleEngineCapacityValidatorSubscription: Subscription | null = null
    FuelGradeMinMaxValidatorSubscription: Subscription | null = null

    canProceed: boolean
    debounceDelay: number = 1000

    exceededEngin: boolean

    validationObject: CriticalValidations



    constructor(props: any) {
        super(props);
        this.validationObject = new CriticalValidations()
        this.exceededEngin = true
        this.canProceed = false
        this.state = {
            attachments: null,
            literAmount: this.props.literAmount,
            amount: this.props.amount,
            meterReading: this.props.meterReading,
            pumpDate: moment(this.props.pumpDate).format('YYYY-MM-DD'),
            externalAttachments: this.props.attachments,
            fuelGrade: this.props.defaultFuelGrade,
            errors: [
                // { message: fuelMinMaxErrorMessage, source: 'FUEL_MAX_MIN' },
                // { message: engineCapacityErrorMessage, source: 'ENGINE_CAPACITY' }
            ]
        }
        this.clearState = this.clearState.bind(this)

        this._onDone = this._onDone.bind(this)
        this._onCancel = this._onCancel.bind(this)
        this.setError = this.setError.bind(this)
        this.removeError = this.removeError.bind(this)
        this._onDateChange = this._onDateChange.bind(this)
        this._onAmountChange = this._onAmountChange.bind(this)
        this._onFuelGradeChange = this._onFuelGradeChange.bind(this)
        this._onMeterRatingChange = this._onMeterRatingChange.bind(this)
        this._onLiterAmountChange = this._onLiterAmountChange.bind(this)
        this.onMultipleUploaderSubmit = this.onMultipleUploaderSubmit.bind(this)
        this._checkForFuelGradeMinMax = this._checkForFuelGradeMinMax.bind(this)
        this._checkForVehicleEngineCapacity = this._checkForVehicleEngineCapacity.bind(this)
    }

    componentDidMount() {
        // "meter-reading": this.state.meterReading,
        // "fuel-grade": this.props.fuelGrades[0].id,
        // "liters-amount": this.state.literAmount,
        // "invoice-amount": this.state.amount
        // if(this.formRef.current){
        //     const _current = this.formRef.current;
        //     this.formRef.current.setFields([
        //         {name: "meter-reading", value: 0},
        //         {name: "fuel-grade", value: this.state.fuelGrade},
        //         {name: "liters-amount", value: this.state.literAmount},
        //         {name: "invoice-amount", value: this.state.amount},
        //         {name: "date-picker", value: this.state.pumpDate}
        //     ])
        // }

        this.initialValidation()

        this.VehicleEngineCapacityValidatorSubscription =
            this.VehicleEngineCapacityValidator
                .pipe(debounceTime(this.debounceDelay))
                .subscribe((_) =>
                    this._checkForVehicleEngineCapacity())

        this.FuelGradeMinMaxValidatorSubscription =
            this.FuelGradeMinMaxValidator
                .pipe<any>(debounceTime(this.debounceDelay))
                .subscribe((_) =>
                    this._checkForFuelGradeMinMax())
    }

    async initialValidation() {
        await this._checkForVehicleEngineCapacity()
        await this._checkForFuelGradeMinMax()
    }
    ///// MODAL FORM

    _onMeterRatingChange(value: any) {
        this.setState({
            meterReading: value
        })
    }

    _onDateChange(value: any | null, dateString: string) {
        this.setState({
            pumpDate: dateString
        }, () => this.VehicleEngineCapacityValidator.next(dateString))
    }

    _onAmountChange(value: any) {
        this.setState({
            amount: value
        }, () => this.FuelGradeMinMaxValidator.next(value))
    }

    _onLiterAmountChange(value: any) {
        this.setState({
            literAmount: value
        }, () => {
            this.VehicleEngineCapacityValidator.next(value);
            this.FuelGradeMinMaxValidator.next(value)
        })
    }

    _onFuelGradeChange(value: number) {
        this.setState({
            fuelGrade: new FuelGrade({ id: value, name: "" })
        }, () => this.FuelGradeMinMaxValidator.next(value))
    }

    async _checkForFuelGradeMinMax() {
        try {
            const body = {
                "fuelGradId": this.state.fuelGrade.id,
                "fuelLiters": this.state.literAmount,
                "ammount": this.state.amount
            }
            // console.log(JSON.stringify(body))
            const res = await lionUserManager.authenticationManager.post('/fuelClaim/FuelClaim/CheckFuelGradMinMaxCondition', body)

            if (res.status == 200) {
                // console.log(res.data)
                if (res.data.condition && res.data.condition == true) {
                    this.validationObject.value.FuelGradeMinMaxValidator = res.data.condition
                    this.removeError('FUEL_MAX_MIN')
                } else throw new Error(fuelMinMaxErrorMessage)
            } else
                throw new Error(fuelMinMaxErrorMessage)

        } catch (err) {
            this.validationObject.value.FuelGradeMinMaxValidator = false
            this.setError({ message: err.message, source: 'FUEL_MAX_MIN' })
        }
    }

    async _checkForVehicleEngineCapacity() {
        try {
            const body = {
                "employeeId": lionUserManager.user.data.USER_ID,
                "vehicalId": this.props.vehicle.id,
                "fuelLiters": this.state.literAmount,
                "pumpedDate": this.state.pumpDate
            }
            const res = await lionUserManager.authenticationManager.post('/fuelClaim/FuelClaim/CheckVhicalEngingCapacity', body)

            if (res.status == 200) {
                // console.log(res.data)
                if (res.data.condition && res.data.condition == true) {
                    this.validationObject.value.VehicleEngineCapacity = res.data.condition
                    this.exceededEngin = res.data.highEnginCapacitor
                    this.removeError('ENGINE_CAPACITY')
                } else throw new Error(engineCapacityErrorMessage)
            } else
                throw new Error(engineCapacityErrorMessage)

        } catch (err) {
            this.validationObject.value.VehicleEngineCapacity = false
            this.exceededEngin = true
            this.setError({ message: err.message, source: 'ENGINE_CAPACITY' })
        }
    }

    setError(error: FormError) {
        this.setState((prevState: EditClaimFormState) => {
            if (prevState.errors &&
                !prevState.errors.find((er: FormError) => er.source == error.source))
                prevState.errors.push(error)
            return prevState;
        })
    }

    removeError(source: string) {
        this.setState((prevState: EditClaimFormState) => {
            prevState.errors = prevState.errors && prevState.errors.filter((er: FormError) =>
                er.source != source)
            return prevState;
        })
    }

    clearState() {
        this.setState({
            attachments: null,
            literAmount: this.props.literAmount,
            amount: this.props.amount,
            meterReading: this.props.meterReading,
            pumpDate: this.props.pumpDate,
            externalAttachments: [],
            fuelGrade: this.props.defaultFuelGrade,
            errors: [
                // { message: fuelMinMaxErrorMessage, source: 'FUEL_MAX_MIN' },
                // { message: engineCapacityErrorMessage, source: 'ENGINE_CAPACITY' }
            ]
        })
    }

    onMultipleUploaderSubmit(data: UploaderState) {
        this.setState({
            attachments: data
        })
    }

    componentWillUnmount() {
        this.VehicleEngineCapacityValidatorSubscription &&
            this.VehicleEngineCapacityValidatorSubscription.unsubscribe()
        this.FuelGradeMinMaxValidatorSubscription &&
            this.FuelGradeMinMaxValidatorSubscription.unsubscribe()
    }

    async _onCancel() {
        this.clearState()
        this.formRef.current && this.formRef.current.resetFields()
        this.props.onCancel && this.props.onCancel()
    }

    async _onDone() {
        try {

            if (this.formRef.current)
                await this.formRef.current.validateFields()

            if (!this.state.attachments || this.state.attachments.files.length < 1) {
                if (!this.state.externalAttachments || this.state.externalAttachments.length < 1) {
                    throw new Error("Please upload file")
                }
            }

            if (
                this.validationObject.isValid()
            ) {
                const _is = await this.props.onDone({
                    fuelGrade: this.state.fuelGrade,
                    files: this.state.attachments ? this.state.attachments.files : [],
                    pumpDate: this.state.pumpDate,
                    amount: this.state.amount,
                    meterReading: this.state.meterReading,
                    exceededEngin: this.exceededEngin,
                    liters: this.state.literAmount,
                    externalAttachments: this.state.externalAttachments
                })
                if (_is) {
                    this.formRef.current && this.formRef.current.resetFields()
                    this.clearState()
                }
            }

        }
        catch (err) {
            const _message = err.message
            message.error(!_message ? "Please fill in the required fields" : _message)
        }
    }

    _removeExternalFile(file: any) {
        this.setState((prevState: EditClaimFormState) => {
            prevState.externalAttachments = prevState.externalAttachments.filter((_file) => file.id != _file.id);
            return prevState
        })
    }

    /////

    render() {
        return (
            <Modal
                title={this.props.title}
                style={{ top: 20 }}

                visible={this.props.isVisible}
                onCancel={this._onCancel}
                okText="Done"

                onOk={this._onDone}>
                {
                    this.state.errors &&
                    this.state.errors.map((error: FormError) =>
                        <div key={error.source} style={{ marginBottom: 10 }}>
                            <Alert message={error.message} type="warning" showIcon />
                        </div>)
                }

                <Form
                    {...layout}
                    initialValues={{
                        "meter-reading": this.state.meterReading,
                        "fuel-grade": this.state.fuelGrade.id,
                        "liters-amount": this.state.literAmount,
                        "invoice-amount": this.state.amount,
                        "date-picker": moment(this.state.pumpDate)
                    }}
                    ref={this.formRef}
                    name="nest-messages"

                    onFinish={this._onDone}
                    validateMessages={validateMessages}>
                    <Form.Item
                        name="meter-reading"
                        label="Meter Reading"
                        rules={[
                            {
                                required: true,
                                message: 'This field is required!',
                            },
                            {
                                type: 'number',
                                message: 'Meter reading is not valid',
                            },
                            {
                                validator(_, value, callback) {
                                    if (value > 0)
                                        callback()
                                    callback("Value must be greater than zero")
                                }
                            }
                        ]}>
                        <InputNumber min={0} onChange={this._onMeterRatingChange} style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item
                        name="fuel-grade"
                        label="Fuel Grade"
                        rules={[
                            {
                                required: true,
                                message: 'This field is required!',
                            }, {
                                type: 'number',
                                message: 'Please select a fuel grade',

                            },
                        ]}>
                        <Select onChange={this._onFuelGradeChange} value={this.props.defaultFuelGrade && this.props.defaultFuelGrade.id}>
                            {
                                this.props.fuelGrades.map((grade: FuelGrade) =>
                                    <Select.Option value={grade.id} key={grade.id}>{grade.name}</Select.Option>)
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="liters-amount"
                        label="Liter Amount"
                        rules={[
                            {
                                required: true,
                                message: 'This field is required!',
                            },
                            {
                                type: 'number',
                                message: 'Please provide a valid amount',
                            },
                        ]}>
                        <InputNumber min={0} onChange={this._onLiterAmountChange} style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item
                        name="date-picker"
                        label="Pump Date"
                        rules={[
                            {
                                required: true,
                                message: 'This field is required!',
                            },
                            {
                                type: 'object',
                                message: 'Please select date & time!',
                            },
                        ]}>
                        <DatePicker disabledDate={(current: Moment) => current && current > moment().endOf('day')} onChange={this._onDateChange} style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item
                        name="invoice-amount"
                        label="Invoice Amount"
                        rules={[
                            {
                                required: true,
                                message: 'This field is required!',
                            },
                            {
                                type: 'number',
                                message: 'Please provide a valid amount',
                            },
                        ]}>
                        <InputNumber min={0} onChange={this._onAmountChange} style={{ width: '100%' }} />
                    </Form.Item>
                    <Row style={{ marginBottom: 10 }} gutter={10}>
                        {
                            this.state.externalAttachments.map((file) => <Col onClick={() => this._removeExternalFile(file)} style={{ height: 70, width: 70, display: 'flex', justifyContent: "center", alignItems: 'center' }}>
                                <img src={imgBaseUrl + file.name} style={{ width: "100%" }} />
                                <DeleteOutlined style={{ position: 'absolute', color: 'white', padding: 5, borderRadius: '100%', background: '#00000070' }} />
                            </Col>)
                        }

                    </Row>
                    <MultipleFileUploader text="Support for a single or bulk upload." heading="Click or drag  file to this area to upload  fuel bill." justPreview={true} accept={defaultImageExtensions} onChange={this.onMultipleUploaderSubmit} />
                </Form>
            </Modal>
        );
    }
}

interface VehicleSelectorState {
    currentVehicle: Vehicle,
    isModalVisible: boolean
}

interface VehicleSelectorProps {
    data: Array<Vehicle>
    default: Vehicle
    onChange: (vehicle: Vehicle) => void
}

class VehicleSelector extends Component<VehicleSelectorProps, VehicleSelectorState>{
    constructor(props: any) {
        super(props)
        this._onClick = this._onClick.bind(this)
        this.state = {
            isModalVisible: false,
            currentVehicle: this.props.default
        }
    }

    _onClick(vehicle: Vehicle) {
        this.setState({
            currentVehicle: vehicle,
            isModalVisible: false
        })
        this.props.onChange(vehicle)
    }

    render() {
        return (
            <React.Fragment>
                {this.state.currentVehicle && <VehicleCard onClick={() => this.setState({ isModalVisible: true })} vehicle={this.state.currentVehicle} />}
                <Modal footer={<div></div>} onCancel={() => this.setState({ isModalVisible: false })} visible={this.state.isModalVisible}>
                    <List
                        itemLayout="horizontal"
                        dataSource={this.props.data}
                        renderItem={(item: Vehicle) => (
                            <div style={{ cursor: 'pointer' }} onClick={() => this._onClick(item)}>
                                <List.Item>
                                    <List.Item.Meta
                                        title={item.plateNumber}
                                        description={`${item.engineCapacity.name}: ${item.fuelType.name}`}
                                    />
                                </List.Item>
                            </div>
                        )}
                    />
                </Modal>
            </React.Fragment>
        )
    }
}

// const mapDispatchToProps = (dispatch: any) => ({
//     actions: {
//         vehicalRepoActions: bindActionCreators(VehicleRepoActions, dispatch),
//         claimRepoActions: bindActionCreators(ClaimRepoActions, dispatch)
//     }
// })

// const withVehicleRepo = connect(((state) => state), VehicleRepoActions)(EditClaim);
// const withClaimRepo = connect(((state) => state), ClaimRepoActions)(withVehicleRepo);
// export default withRouter(withClaimRepo as FunctionComponent); 