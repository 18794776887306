import React, { FunctionComponent } from "react";

import {
	Table,
	Tag,
	message,
	Spin,
	Drawer,
	Modal,
	Button,
	Input,
	Form,
	DatePicker,
	PageHeader,
	Descriptions,
} from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { ClaimRepoActions } from "../repos/ClaimRepo";
// import logo from '../logo.png'
import "./style.css";
import { lionUserManager } from "../repos/UserRepo";
import { bool, boolean } from "@hapi/joi";
import moment, { Moment } from "moment";
import { toLKR } from "./CreateLoanRequest";
const { Column } = Table;
const { TextArea } = Input;
const dateFormat = "YYYY-MM-DD";

class CloseLoanRequest extends React.Component<any, any> {
	constructor(props: any) {
		super(props);

		this.state = {
			claims: [],
			loading: false,
			visibleView: false,
			reason: false,
			visible: false,
			cheque: "",
			id: "",
			paymentStartDate: 0,
		};
	}

	componentDidMount() {
		this._getData();
	}

	async _getData() {
		this.setState({ loading: true });
		await this.props.getLoanForClose();
		this.setState({ loading: false });
	}

	approve = async (data: any) => {
		this.setState({ loading: true });
		const form = new FormData();
		form.append("chequNumber", this.state.cheque);
		form.append("loanId", data);
		form.append("dateOfChequ", this.state.paymentStartDate);
		var res = await this.props.submitChequeNumber(form);
		if (res) {
			this._getData();
			message.success("SUCCESSFULLY SUBMITTED");
		} else {
			message.error("SOMETHING WENT WRONG");
		}
	};

	viewDetails(id: any) {
		var result = this.state.claims.filter((obj: any) => {
			return obj.id === id;
		});

		this.setState({
			visibleView: true,
		});
	}

	onClose = () => {
		this.setState({
			visibleView: false,
		});
	};

	addCheque = (id: any) => {
		this.setState({
			visible: true,
			id: id,
		});
	};

	chequeNumberChange = (reason: any) => {
		this.setState({
			cheque: reason.target.value,
		});
	};

	submitReason = () => {
		if (this.state.paymentStartMonth == 0) {
			message.error("Please Enter the Payment Start Month");
			return;
		}
		if (this.state.paymentStartDate == 0) {
			message.error("Please Enter the Payment Start Date");
			return;
		}

		if (this.state.cheque == "") {
			message.error("Please Enter the Cheque Number");
			return;
		}

		(async () => {
			await this.approve(this.state.id);
		})();

		this.setState({ visible: false });
	};

	handleCancel = (e: any) => {
		this.setState({
			visible: false,
		});
	};

	onDateChange = (date: any, dateString: any) => {
		console.log(dateString);

		this.setState({
			paymentStartDate: dateString,
		});
	};

	closeLoan = async (id: string) => {
		let hide = message.loading("Pending");
		try {
			const form = new FormData();
			form.append("loanId", id);

			const res = await lionUserManager.authenticationManager.post(
				"/staffLoan/StaffLoan/collectingTheChequ",
				form
			);

			if (res.status === 200) {
				message.success("Done");
				await this._getData();
			}
		} catch (err) {
			message.error((err as Error).message);
		} finally {
			hide();
		}
	};

	renderGuarantorView = (data: any) => {
		return (
			<div>
				Name: {data.name ?? "N/A"} <br />
				Department: {data.departmentName} <br />
				Approved Time: {moment(data.approvedTime).format("YYYY-MM-DD LT")}
			</div>
		);
	};

	renderExtra = (record: any) => {
		const {
			maturedDay,
			name,
			note,
			rejectReason,
			interestRate,
			installmentAmount,
			dateOfChequ,
			chequNumber,
			gurantors,
			ramainingInstallment,
			remainingAmount,
		} = record;
		const [firstGuarantor, secondGuarantor] = gurantors;
		return (
			<Descriptions bordered={false} size='small'>
				<Descriptions.Item label='Name'>{name}</Descriptions.Item>
				<Descriptions.Item label='Date Of Cheque'>
					{dateOfChequ ? moment(dateOfChequ).format("YYYY-MM-DD h:mm:ss a") : "N/A"}
				</Descriptions.Item>
				<Descriptions.Item label='Installment Amount'>
					{toLKR(installmentAmount)}
				</Descriptions.Item>
				<Descriptions.Item label='Interest Rate'>{interestRate}</Descriptions.Item>
				<Descriptions.Item label='Cheque Number'>
					{chequNumber ?? "N/A"}
				</Descriptions.Item>
				<Descriptions.Item label='Matured Day'>
					{maturedDay ? moment(maturedDay).format("YYYY-MM-DD h:mm:ss a") : "N/A"}
				</Descriptions.Item>
				<Descriptions.Item label='Guarantor I'>
					{this.renderGuarantorView(firstGuarantor)}
				</Descriptions.Item>
				<Descriptions.Item label='Guarantor II'>
					{this.renderGuarantorView(secondGuarantor)}
				</Descriptions.Item>
				<Descriptions.Item label='Note'>{note}</Descriptions.Item>
				{rejectReason && (
					<Descriptions.Item label='Rejected Reason'>
						{rejectReason}
					</Descriptions.Item>
				)}
				<Descriptions.Item label='Remaining Installment'>
					{ramainingInstallment}
				</Descriptions.Item>
				<Descriptions.Item label='Remaining Amount'>
					{toLKR(remainingAmount)}
				</Descriptions.Item>
			</Descriptions>
		);
	};

	render() {
		return (
			<div>
				<Spin size='small' spinning={this.state.loading}>
					<PageHeader
						title='Close Loan Requests'
						onBack={() => window.history.back()}
					/>

					<Table
						size='small'
						expandable={{
							expandedRowRender: this.renderExtra,
						}}
						dataSource={
							this.props.ClaimRepo.laonsForClose?.map((item: any) => ({
								...item,
								key: item.id,
							})) ?? []
						}>
						<Column
							title='Loan Id'
							dataIndex='id'
							render={(value, item, index) => value}
						/>
						<Column width='100' title='EPF No' dataIndex='epfNo' />
						<Column
							width='100'
							title='Loan Amount'
							dataIndex='loanAmount'
							render={(value, item, index) => toLKR(value)}
						/>
						<Table.Column
							title='Guarantor I'
							dataIndex='gurantors'
							render={(val, _, __) => val[0].name ?? "N/A"}
						/>
						<Table.Column
							title='Guarantor II'
							dataIndex='gurantors'
							render={(val, _, __) => val[1].name ?? "N/A"}
						/>
						<Table.Column
							title='Created On'
							dataIndex='createdOn'
							render={(val, _, __) => moment(val).format("YYYY-MM-DD h:mm:ss a")}
						/>
						<Column
							title='Action'
							key='id'
							render={(value, item, index) => (
								<div>
									{value.processingStatus === 5 && (
										<span>
											<Tag color='#1890ff' onClick={() => this.addCheque(value.id)}>
												ADD CHEQUE NO
											</Tag>
										</span>
									)}

									{value.processingStatus === -8 && (
										<span>
											<Tag color='#a318ff' onClick={() => this.closeLoan(value.id)}>
												CLOSE
											</Tag>
										</span>
									)}
								</div>
							)}
						/>
					</Table>
				</Spin>

				<Modal
					title='Please Enter The Cheque Number'
					visible={this.state.visible}
					onCancel={this.handleCancel}
					footer={[
						<Button key='submit' type='primary' onClick={this.submitReason}>
							Submit
						</Button>,
					]}>
					<Form.Item label='Payment Start Date'>
						<DatePicker
							allowClear={false}
							onChange={this.onDateChange}
							picker='date'
							format={dateFormat}
						/>
					</Form.Item>
					<TextArea
						placeholder='Cheque Number'
						rows={4}
						onChange={this.chequeNumberChange}
						value={this.state.cheque}
					/>
				</Modal>
			</div>
		);
	}
}

const WrappedApproveClaim = CloseLoanRequest;
const withDashboardRepo = connect(
	(state: any) => state,
	ClaimRepoActions
)(WrappedApproveClaim);
export default withDashboardRepo;
