interface IVehicle{
    id: number
    plateNumber: string
    status: number
    enterDate: string 
    engineCapacity: EngineCapacity
    fuelType: FuelType
    fuelGrade: FuelGrade
    type: VehicleType
}

export class Vehicle implements IVehicle{
    id: number
    plateNumber: string
    status: number
    enterDate: string
    engineCapacity: EngineCapacity
    fuelType: FuelType
    fuelGrade: FuelGrade
    type: VehicleType

    constructor(props: IVehicle){
        this.id = props.id
        this.plateNumber = props.plateNumber
        this.status = props.status
        this.enterDate = props.enterDate
        this.engineCapacity = props.engineCapacity
        this.fuelType = props.fuelType
        this.fuelGrade = props.fuelGrade
        this.type = props.type
    }
}

interface IWithIdAndName {
    id: number
    name: string
}

class WithIdAndName implements IWithIdAndName{
    id: number
    name: string
    constructor(props: IWithIdAndName){
        this.id = props.id
        this.name = props.name
    }
}

// interface IFuelType extends withIdAndName{

// }

export class FuelType extends WithIdAndName{

}

// interface IFuelGrade extends withIdAndName{

// }

export class FuelGrade extends WithIdAndName{
    
}

// interface IEngineCapacity extends withIdAndName{

// }

export class EngineCapacity extends WithIdAndName{
    
}

// interface IEngineMakes extends withIdAndName{

// }

export class EngineMakes extends WithIdAndName{
    
}

// interface IVehicleModels extends withIdAndName{

// }

export class VehicleModel extends WithIdAndName{
    
}

export class VehicleType extends WithIdAndName{

}