import React, { Component } from 'react';
import { Row, Col, Button } from 'antd'
import { FieldTimeOutlined } from '@ant-design/icons'
import { Vehicle } from '../../repos/models/Vehicle';

export interface MeterReadingCardProps {
    value: number
}

export default class extends Component<MeterReadingCardProps>{
    componentDidMount() {
        console.log(this.props)
    }
    // {this.props.vehicle.plateNumber}
    render() {
        return (
            <Row className="meter-reading-card" justify="center" align="middle" style={{height: '100%', color: '#ffffff'}}>
                <Col >
                    <FieldTimeOutlined style={{ fontSize: 25}} />
                </Col>
                <Col flex="auto" style={{ padding: '0 15px' }}>
                    <Row style={{ fontSize: 13, lineHeight: 1.5 }}>Meter Reading</Row>
                    <Row style={{ fontSize: 15, fontWeight: 600, lineHeight: 1 }}>{this.props.value}</Row>
                </Col>
            </Row>
        )
    }
}