import React, {
	FunctionComponent,
	Component,
	createRef,
	RefObject,
} from "react";
import moment, { Moment } from "moment";
import { connect } from "react-redux";
import {
	Descriptions,
	Form,
	Input,
	Row,
	Col,
	Table,
	Button,
	Select,
	Spin,
	message,
	Tag,
	Tooltip,
	Switch,
	InputNumber,
} from "antd";
import { FormInstance } from "antd/lib/form";
import { withRouter } from "react-router";
import { ClaimRepoActions } from "../repos/ClaimRepo";
import { Claim } from "../repos/models/Claim";
import _ from "lodash";
import "./style.css";
import { lionUserManager } from "../repos/UserRepo";
import { textSpanIntersectsWithTextSpan } from "typescript";
import { Subject, Subscription } from "rxjs";
import { debounceTime } from "rxjs/operators";
const { TextArea } = Input;
const { Column, ColumnGroup } = Table;
const layout = {
	labelCol: {
		span: 8,
	},
	wrapperCol: {
		span: 16,
	},
};

const layout1 = {
	labelCol: {
		span: 8,
	},
	wrapperCol: {
		span: 16,
	},
};

class TableClaimItem extends Claim {
	key: number;
	constructor(claim: Claim, key: number) {
		super(claim);
		this.key = key;
	}
}

function formatCurrency(value: number) {
	return (Math.round(value * 100) / 100)
		.toFixed(2)
		.toString()
		.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function toLKR(value: number) {
	return Intl.NumberFormat("en-IN", {
		style: "currency",
		currency: "LKR",
		currencyDisplay: "narrowSymbol",
	}).format(value);
}


class CreateLoanRequest extends React.Component<any, any> {
	formRef: RefObject<FormInstance> = React.createRef<FormInstance>();
	loanCreateformRef: RefObject<FormInstance> = React.createRef<FormInstance>();
	loanAmountChangeListenerSubject: Subject<any>;
	loanAmountChangeListenerSubjectSubscription?: Subscription;

	constructor(props: any) {
		super(props);
		// this.state = this.defaultStateObject;
		this.onLoanAmountChange = this.onLoanAmountChange.bind(this);
		this.onIsLessThanFixLoan = this.onIsLessThanFixLoan.bind(this);
		this.loanAmountChangeListenerSubject = new Subject<any>();
		this.state = {
			loanAmountList: [],
			departments: [],
			firstGuarantor: [],
			secondGuarantor: [],
			firstGuarantorId: "",
			firstGuarantorStructureLevelCode: "",
			secondGuarantorStructureLevelCode: "",
			secondGuarantorId: "",
			firstGuarantorDepId: "",
			secondGuarantorDepId: "",
			minLoanAmount: 50000,
			monthlyPayment: 0,
			note: "",
			// loanAmount: 0,
			isLessThanFixLoan: false,
			loading: false,
			remainingAmount: 0,
			totalLoanAmount: 0,
			loanPeriod: "",
			interestRate:"",
			installmentAmount: ""
		};
	}

	componentDidMount() {
		this.setState({
			loading: true,
		});
		this._getData();
		this.loanAmountChangeListenerSubjectSubscription =
			this.loanAmountChangeListenerSubject
				.pipe(debounceTime(1000))
				.subscribe(async (value) => {
					if (value) {
						var data = new FormData();
						data.append("loanAmount", value);
						const res = await lionUserManager.authenticationManager.post(
							"/staffLoan/StaffLoan/monthlyAmount",
							data
						);
						if (res.status === 200) {
							this.setState({
								monthlyPayment: res.data ?? 0,
							});
						}
                        console.log({value, res})
					}
				});
	}

	componentWillUnmount() {
		this.loanAmountChangeListenerSubjectSubscription &&
			this.loanAmountChangeListenerSubjectSubscription.unsubscribe();
	}

	async _getData() {
		await this.props.getDepartment();
		await this.props.getMasterData();
		await this.props.getUserStatus();
		this.setState({
			departments: this.props.ClaimRepo.departments,
			loading: false,
		});
		//await this.props.getUserHistory2();

		console.log(this.props.ClaimRepo.userStatus);

        
        if (this.props.ClaimRepo.masterData) {
            const _monthlyAmount = this.props.ClaimRepo.masterData[3].amount;
			const _loanAmountList = this.props.ClaimRepo.masterData.filter((obj) => {
				return obj.discription === 'Loan';
			});

            this.setState({
                monthlyPayment: _monthlyAmount,
				loanAmountList: _loanAmountList,
				loanPeriod: this.props.ClaimRepo.masterData[2].amount,
				interestRate: this.props.ClaimRepo.masterData[1].amount,
            })
		}
	}

	departmentChange = async (departmentId: any) => {
		await this.props.getGuarantor(departmentId.split("_")[0]);
		this.setState({
			firstGuarantorDepId: departmentId.split("_")[0],
			firstGuarantor: this.props.ClaimRepo.guarantors,
			firstGuarantorStructureLevelCode: departmentId.split("_")[1],
			firstGuarantorId:
				Object.keys(this.props.ClaimRepo.guarantors).length != 0
					? this.props.ClaimRepo.guarantors[0].id
					: "",
		});
	};

	GuarantorTwodepartmentChange = async (departmentId: any) => {
		await this.props.getGuarantor(departmentId.split("_")[0]);
		this.setState({
			secondGuarantorDepId: departmentId.split("_")[0],
			secondGuarantorStructureLevelCode: departmentId.split("_")[1],
			secondGuarantor: this.props.ClaimRepo.guarantors,
			secondGuarantorId:
				Object.keys(this.props.ClaimRepo.guarantors).length != 0
					? this.props.ClaimRepo.guarantors[0].id
					: "",
		});
	};

	firstGuarantorChange = (id: any) => {
		this.setState({
			firstGuarantorId: id,
		});
	};

	secondGuarantorChange = (id: any) => {
		this.setState({
			secondGuarantorId: id,
		});
	};

	onNoteChange = (e: any) => {
		this.setState({ note: e.target.value });
	};

	submit = async (status: any) => {
		if (!this.checkValidation()) {
			return;
		}
		this.setState({
			loading: true,
		});

		let _loanValue =
			this.loanCreateformRef.current?.getFieldValue("loan_amount") ??
			(this.props.ClaimRepo.masterData
				? this.props.ClaimRepo.masterData[0].amount
				: 0.0);

		console.log({ _loanValue });

		var data = {
			employeeId: lionUserManager.user.data.USER_ID,
			note: this.state.note,
			actveStatus: 1,
			status: status,
			processingStatus: status,
			loanAmount: _loanValue,
			// isLessThanFixLoan: this.state.isLessThanFixLoan ? 0 : 1,
			gurantors: [
				{
					approvedBy: this.state.firstGuarantorId,
					departmentId: this.state.firstGuarantorDepId,
					syncStructureLevelCode: this.state.firstGuarantorStructureLevelCode,
				},
				{
					approvedBy: this.state.secondGuarantorId,
					departmentId: this.state.secondGuarantorDepId,
					syncStructureLevelCode: this.state.secondGuarantorStructureLevelCode,
				},
			],
			remainingAmount: this.state.totalLoanAmount,
			totalLoanAmount: this.state.totalLoanAmount,
			loanPeriod: this.state.loanPeriod,
			interestRate: this.state.interestRate,
			totalLoanPeriod: this.state.loanPeriod,
			installmentAmount: this.state.monthlyPayment
		};

		console.log({ data });

		const form = new FormData();
		form.append("loan", JSON.stringify(data));
		var res = await this.props.applyLoan(form);

		const { match, location, history } = this.props;
		history.push(`/protected/dashboard`);

		// if (res) {
		// 	const { match, location, history } = this.props;
		// 	history.push(`/protected/dashboard`);
		// } else {
		// 	// message.error("Please try again");
		// }

		this.setState({
			loading: false,
		});
	};

	onLoanAmountChange = async (value: any) => {
		if (value) {
			const _rate = Number(value) * Number(this.state.interestRate);
			const _totalAmount = Number(value) + _rate;
			this.setState({
				remainingAmount: _totalAmount,
				totalLoanAmount: _totalAmount,
				loanAmount: value,
			});
			if (this.loanCreateformRef.current) {
				this.loanCreateformRef.current.setFields([
					{
						name: "loan_amount",
						value: value,
					},
				]);
			}

			this.loanAmountChangeListenerSubject.next(value);
		}
	};

	onIsLessThanFixLoan(value: boolean) {
		/// value: true -> flexible
		/// value: false -> fixed

		var _loanAmount;

		// if "fixed" set loanAmount to repo value
		if (!value) {
			if (this.props.ClaimRepo.masterData) {
				_loanAmount = this.props.ClaimRepo.masterData[0].amount;
			}
		}

		this.setState({
			isLessThanFixLoan: value,
		});

		this.onLoanAmountChange(_loanAmount);
	}

	checkValidation() {
		if (
			this.state.note == "" ||
			this.state.firstGuarantorId == "" ||
			this.state.firstGuarantorDepId == "" ||
			this.state.secondGuarantorId == "" ||
			this.state.secondGuarantorDepId == ""
		) {
			message.error("Please Fill All the fields");
			return false;
		}
		if (this.state.firstGuarantorId == this.state.secondGuarantorId) {
			message.error("You Cannot use the same Guarantor");
			return false;
		}

		return true;
	}

	render() {
		const maxLoanAmount = this.props.ClaimRepo.masterData
			? this.props.ClaimRepo.masterData.find(item => item.discription === 'MaxAmount').amount
			: 0.0;
			
		const minLoanAmount = this.props.ClaimRepo.masterData
			? this.props.ClaimRepo.masterData.find(item => item.discription === 'MinAmount').amount
			: 0.0;

		return (
			<div>
				<Spin size='small' spinning={this.state.loading}>
					{!this.props.ClaimRepo.userStatus && (
						<Tag color='red'>You Already Have An Active Loan</Tag>
					)}
					<Descriptions title='Personal Loan Application Form' bordered>
						<Descriptions.Item label='Name Of Applicant'>
							{lionUserManager.user.data.User.fullName}
						</Descriptions.Item>
						<Descriptions.Item label='EPF No'>
							{lionUserManager.user.data.User.epfNo}
						</Descriptions.Item>
						{/* <Descriptions.Item label="Date">YES</Descriptions.Item> */}
						<Descriptions.Item label='Designation'>
							{lionUserManager.user.data.User.designationName}
						</Descriptions.Item>
						<Descriptions.Item label='Company Name'>
							{lionUserManager.user.data.User.company}
						</Descriptions.Item>
					</Descriptions>
					<div style={{ marginTop: 20 }} className='ant-descriptions-title'>
						Request Reason <span style={{color: "red"}}>*</span>
					</div>
					<TextArea maxLength={100} rows={4} onChange={this.onNoteChange} />
					<div style={{ marginTop: 20 }}>
						<Row>
							<Col span={8}>
								<Form {...layout} ref={this.loanCreateformRef} name='control-ref'>
									<Row align='middle' justify='space-between'>
										<Col>
											<h4 style={{ marginBottom: 20 }}>Staff Loan Details</h4>
										</Col>
										{/* <Col>
											<Switch
												checkedChildren='Flexible'
												unCheckedChildren='Fixed'
												checked={this.state.isLessThanFixLoan}
												onChange={this.onIsLessThanFixLoan}
											/>
										</Col> */}
									</Row>
									<Form.Item label='Loan Range'>
										<Row style={{ marginBottom: 10 }}>
											<Col>
												<Tooltip title='Maximum Loan Amount'>
													<Tag color='geekblue'>
														▲ {formatCurrency(maxLoanAmount)}
													</Tag>
												</Tooltip>
											</Col>
											<Col>
												<Tooltip title='Minimum Loan Amount'>
													<Tag color='purple'>
														▼ {formatCurrency(minLoanAmount)}
													</Tag>
												</Tooltip>
											</Col>
										</Row>
									</Form.Item>
									<Form.Item
										name='loan_amount'
										label='Loan Amount (Rs)'
										rules={[
											{
												required: true,
												message: "Loan amount is required",
											},
										]}>
										<Select
											onChange={this.onLoanAmountChange}
											optionFilterProp='children'>
											{this.state.loanAmountList.map((key: any) => (
												<Select.Option value={key.amount}>
													{key.amount}
												</Select.Option>
											))}
										</Select>
									</Form.Item>
									<Form.Item
										name='Loan Period'
										label='Loan Period'
										rules={[
											{
												required: true,
											},
										]}>
										<Input
											placeholder={
												this.state.loanPeriod
											}
											disabled
										/>
									</Form.Item>
									<Form.Item
										name='Monthly Payment'
										label='Monthly Payment'
										rules={[
											{
												required: true,
											},
										]}>
										<Input
											placeholder={
												// this.props.ClaimRepo.masterData != null
												// 	? "Rs. " +
												// 	  (
												// 			Math.round(this.props.ClaimRepo.masterData[3].amount * 100) / 100
												// 	  )
												// 			.toFixed(2)
												// 			.toString()
												// 			.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
												// 	: ""
												formatCurrency(this.state.monthlyPayment)
											}
											disabled
										/>
									</Form.Item>
								</Form>
							</Col>
							<Col span={8}>
								<Form {...layout} ref={this.formRef} name='control-ref'>
									<h4 style={{ marginBottom: 20, marginLeft: 30 }}>First Guarantor</h4>
									<Form.Item
										label='Department'
										rules={[
											{
												required: true,
											},
										]}>
										<Select
											onChange={this.departmentChange}
											showSearch
											optionFilterProp='children'>
											{this.state.departments.map((key: any) => (
												<Select.Option value={key.id + "_" + key.syncStructureLevelCode}>
													{key.eclaimHiecodeName}
												</Select.Option>
											))}
										</Select>
									</Form.Item>
									<Form.Item
										label='Name'
										rules={[
											{
												required: true,
											},
										]}>
										<Select
											onChange={this.firstGuarantorChange}
											showSearch
											optionFilterProp='children'
											value={this.state.firstGuarantorId}>
											{this.state.firstGuarantor.map((key: any) => (
												<Select.Option value={key.id}>{key.name}</Select.Option>
											))}
										</Select>
									</Form.Item>
								</Form>
							</Col>

							<Col span={8}>
								<Form {...layout1} ref={this.formRef} name='control-ref'>
									<h4 style={{ marginBottom: 20, marginLeft: 30 }}>Second Guarantor</h4>
									<Form.Item
										label='Department'
										rules={[
											{
												required: true,
											},
										]}>
										<Select
											onChange={this.GuarantorTwodepartmentChange}
											showSearch
											optionFilterProp='children'>
											{this.state.departments.map((key: any) => (
												<Select.Option value={key.id + "_" + key.syncStructureLevelCode}>
													{key.eclaimHiecodeName}
												</Select.Option>
											))}
										</Select>
									</Form.Item>
									<Form.Item
										label='Name'
										rules={[
											{
												required: true,
											},
										]}>
										<Select
											onChange={this.secondGuarantorChange}
											showSearch
											optionFilterProp='children'
											value={this.state.secondGuarantorId}>
											{this.state.secondGuarantor.map((key: any) => (
												<Select.Option value={key.id}>{key.name}</Select.Option>
											))}
										</Select>
									</Form.Item>
								</Form>
							</Col>
						</Row>

						<div style={{ marginTop: 25, marginBottom: 25 }}>
							<h3>
								<b>
									{" "}
									I hereby certify that the total authorized deductions from my salary
									(eg - for EPF, ETF Company loans, staff beer and other deductions)
									including if approved the monthly deductions made for this loan - do
									not exceed 60% of my income.{" "}
								</b>
							</h3>	
							<h3>
								{" "}
								I confirm that the information given above by me is true & accurate. I
								agree to provide any further information and adhere to the terms &
								conditions stipulated by the Lion Brewery (Ceylon) PLC. Sports & Welfare
								committee for the grant of the above loan requested by me.{" "}
							</h3>
						</div>
					</div>
					{this.props.ClaimRepo.userStatus && (
						<div>
							{" "}
							<Button type='default' onClick={() => this.submit("0")}>
								Save Draft
							</Button>
							<Button
								style={{ marginLeft: 10 }}
								type='primary'
								onClick={() => this.submit("1")}>
								Send to Approval
							</Button>{" "}
						</div>
					)}
				</Spin>
			</div>
		);
	}
}

const withDashboardRepo = connect(
	(state) => state,
	ClaimRepoActions
)(CreateLoanRequest);
export default withRouter(withDashboardRepo as FunctionComponent);
