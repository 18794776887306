import React from "react";
import { forwardRef } from "react";
import { Col, PageHeader, Row, Table } from "antd";
import { getStatusByCode } from "../statusMapper";

type PritableCurrentLoansListProps = {
	hidden: boolean;
	dataSource: any[];
};

export const PritableCurrentLoansList = forwardRef<
	HTMLDivElement,
	PritableCurrentLoansListProps
>((props, ref) => {
	return (
		<div style={props.hidden ? { display: "none" } : {}}>
			<div ref={ref}>
                <Row>
                    <PageHeader title={"LION BREWERY (CEYLON) PLC"} subTitle="Current Loan List"/>
                </Row>
				<Row gutter={10} style={{margin: "10px 15px"}}>
					<Col flex="auto">
						<Table
							style={{ marginTop: 26, padding: 5 }}
							pagination={false}
							dataSource={props.dataSource}>
							<Table.Column title='Loan Id' dataIndex='id' />
							<Table.Column width='100' title='Epf No' dataIndex='epfNo' />
							<Table.Column width='100' title='Name' dataIndex='name' />
							<Table.Column
								title='Loan Amount'
								dataIndex='loanAmount'
								render={(value, item, index) =>
									"Rs. " +
									(Math.round(value * 100) / 100)
										.toFixed(2)
										.toString()
										.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
								}
							/>
							<Table.Column
								title='Outstanding Amount'
								dataIndex='remainingAmount'
								render={(value, item, index) =>
									"Rs. " +
									(Math.round(value * 100) / 100)
										.toFixed(2)
										.toString()
										.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
								}
							/>
							<Table.Column
								title='Loan Period'
								dataIndex='loanPeriod'
							/>
							<Table.Column
								title='Status'
								width={200}
								dataIndex='processingStatus'
								render={(value, item, index) => getStatusByCode(parseInt(value))?.title}
							/>
							<Table.Column
								title='Date'
								dataIndex='createdOn'
								render={(value, item, index) => value.split("T")[0]}
							/>
						</Table>
					</Col>
				</Row>
			</div>
		</div>
	);
});
