import React, { FunctionComponent, useEffect, useState } from "react";
import {
	Table,
	Tag,
	message,
	Spin,
	Drawer,
	Modal,
	Button,
	Input,
	PageHeader,
	Tabs,
	Descriptions,
} from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { ClaimRepoActions } from "../repos/ClaimRepo";
// import logo from '../logo.png'
import "./style.css";
import { lionUserManager } from "../repos/UserRepo";
import { bool, boolean } from "@hapi/joi";
import moment from "moment";
import { statusMap } from "./statusMapper";
import { toLKR } from "./CreateLoanRequest";
const { Column } = Table;
const { TextArea } = Input;

class _ApproveClaimByHR extends React.Component<any, any> {
	constructor(props: any) {
		super(props);

		this.state = {
			loading: false,
			visibleView: false,
			reason: false,
			visible: false,
			id: "",
		};
	}

	componentDidMount() {
		this._getData();
	}

	async _getData() {
		this.setState({ loading: true });
		await this.props.getHrClaimList();
		this.setState({ loading: false });
	}

	approve = async (data: any, reason: any, status: any) => {
		this.setState({ loading: true });
		const form = new FormData();
		form.append("approveById", lionUserManager.user.data.USER_ID);
		form.append("approve", status);
		form.append("comment", reason);
		form.append("loanId", data);
		var res = await this.props.sendToHrHead(form);
		if (res) {
			message.success("SUCCESSFULLY SUBMITTED");
			this._getData();
		} else {
			message.error("SOMETHING WENT WRONG");
			this._getData();
		}
	};

	viewDetails(id: any) {
		var result = this.state.claims.filter((obj: any) => {
			return obj.id === id;
		});

		this.setState({
			visibleView: true,
		});
	}

	onClose = () => {
		this.setState({
			visibleView: false,
		});
	};

	rejectClaim = async (id: any) => {
		this.setState({
			visible: true,
			id: id,
		});
		await this._getData();
	};

	rejectedReasonTextChange = (reason: any) => {
		this.setState({
			reason: reason.target.value,
		});
	};

	submitReason = () => {
		if (this.state.reason == "") {
			message.error("Please Enter the Reason");
			return;
		}
		(async () => {
			await this.approve(this.state.id, this.state.reason, "0");
		})();

		this.setState({ visible: false });
	};

	handleCancel = (e: any) => {
		this.setState({
			visible: false,
		});
	};

	renderGuarantorView = (data: any) => {
		return (
			<div>
				Name: {data.name ?? "N/A"} <br />
				Department: {data.departmentName} <br />
				Approved Time: {moment(data.approvedTime).format("YYYY-MM-DD LT")}
			</div>
		);
	};

	renderExtra = (record: any) => {
		const {
			maturedDay,
			name,
			note,
			rejectReason,
			interestRate,
			installmentAmount,
			dateOfChequ,
			chequNumber,
			gurantors,
			ramainingInstallment,
			remainingAmount,
		} = record;
		const [firstGuarantor, secondGuarantor] = gurantors;
		return (
			<Descriptions bordered={false} size='small'>
				<Descriptions.Item label='Name'>{name}</Descriptions.Item>
				<Descriptions.Item label='Date Of Cheque'>
					{dateOfChequ ? moment(dateOfChequ).format("YYYY-MM-DD h:mm:ss a") : "N/A"}
				</Descriptions.Item>
				<Descriptions.Item label='Installment Amount'>
					{toLKR(installmentAmount)}
				</Descriptions.Item>
				<Descriptions.Item label='Interest Rate'>{interestRate}</Descriptions.Item>
				<Descriptions.Item label='Cheque Number'>
					{chequNumber ?? "N/A"}
				</Descriptions.Item>
				<Descriptions.Item label='Matured Day'>
					{maturedDay ? moment(maturedDay).format("YYYY-MM-DD h:mm:ss a") : "N/A"}
				</Descriptions.Item>
				<Descriptions.Item label='Guarantor I'>
					{this.renderGuarantorView(firstGuarantor)}
				</Descriptions.Item>
				<Descriptions.Item label='Guarantor II'>
					{this.renderGuarantorView(secondGuarantor)}
				</Descriptions.Item>
				<Descriptions.Item label='Note'>{note}</Descriptions.Item>
				{rejectReason && (
					<Descriptions.Item label='Rejected Reason'>
						{rejectReason}
					</Descriptions.Item>
				)}
				<Descriptions.Item label='Remaining Installment'>
					{ramainingInstallment}
				</Descriptions.Item>
				<Descriptions.Item label='Remaining Amount'>
					{toLKR(remainingAmount)}
				</Descriptions.Item>
			</Descriptions>
		);
	};

	render() {
		return (
			<div>
				<Spin size='small' spinning={this.state.loading}>
					<Table
						expandable={{
							expandedRowRender: this.renderExtra,
						}}
						size='small'
						dataSource={(
							this.props.ClaimRepo.hrClaimList?.map((item: any) => ({
								...item,
								key: item.id,
							})) ?? []
						).reverse()}>
						<Column
							title='Loan Id'
							dataIndex='id'
							render={(value, item, index) => value}
						/>
						<Column width='100' title='EPF No' dataIndex='epfNo' />
						{/* <Column width='100' title='Employee Name' dataIndex='name' key='name' /> */}
						<Column
							width='100'
							title='Loan Amount'
							dataIndex='loanAmount'
							render={(value, item, index) => toLKR(value)}
						/>
						<Table.Column
							title='Guarantor I'
							dataIndex='gurantors'
							render={(val, _, __) => val[0].name ?? "N/A"}
						/>
						<Table.Column
							title='Guarantor II'
							dataIndex='gurantors'
							render={(val, _, __) => val[1].name ?? "N/A"}
						/>
						<Table.Column
							title='Created On'
							dataIndex='createdOn'
							render={(val, _, __) => moment(val).format("YYYY-MM-DD h:mm:ss a")}
						/>
						<Column
							title='Action'
							key='id'
							render={(value, item, index) => (
								<div>
									<span onClick={() => this.approve(value.id, "Approved", "1")}>
										<Tag color='#875ec4'> Approve</Tag>
									</span>
									<span onClick={() => this.rejectClaim(value.id)}>
										<Tag color='#cd201f'> Reject</Tag>
									</span>
								</div>
							)}
						/>
					</Table>
				</Spin>

				<Modal
					title='Please Enter The Rejected Reason'
					visible={this.state.visible}
					onCancel={this.handleCancel}
					footer={[
						<Button key='submit' type='primary' onClick={this.submitReason}>
							Submit
						</Button>,
					]}>
					<TextArea
						rows={4}
						onChange={this.rejectedReasonTextChange}
						value={this.state.reason}
					/>
				</Modal>
			</div>
		);
	}
}

export const ApproveClaimByHR = connect(
	(state: any) => state,
	ClaimRepoActions
)(_ApproveClaimByHR);

async function fetchHrApprovalHistory(userId: number) {
	try {
		const res = await lionUserManager.authenticationManager.get(
			`/staffLoan/StaffLoan/getHistoryOfAllHR/${userId}`
		);
		if (res.status === 200) {
			return res.data;
		} else throw Error("Network Error");
	} catch (err) {
		message.error((err as Error).message);
		return [];
	}
}

function HistoryView() {
	const [busy, setBusy] = useState(false);
	const [data, setData] = useState([]);

	useEffect(() => {
		const fetchData = async () => {
			setBusy(true);
			let _data = await fetchHrApprovalHistory(lionUserManager.user.data.USER_ID);
			setData(_data.map((item) => ({ ...item, key: item.id })));
			setBusy(false);
		};
		fetchData();
	}, []);

	function renderGuarantorView(data: any) {
		return (
			<div>
				Name: {data.name ?? "N/A"} <br />
				Department: {data.departmentName} <br />
				Approved Time: {moment(data.approvedTime).format("YYYY-MM-DD LT")}
			</div>
		);
	}

	function renderExtra(record: any) {
		const {
			maturedDay,
			name,
			note,
			rejectReason,
			interestRate,
			installmentAmount,
			dateOfChequ,
			chequNumber,
			gurantors,
			ramainingInstallment,
			remainingAmount,
		} = record;
		const [firstGuarantor, secondGuarantor] = gurantors;
		return (
			<Descriptions bordered={false} size='small'>
				<Descriptions.Item label='Name'>{name}</Descriptions.Item>
				<Descriptions.Item label='Date Of Cheque'>
					{dateOfChequ ? moment(dateOfChequ).format("YYYY-MM-DD h:mm:ss a") : "N/A"}
				</Descriptions.Item>
				<Descriptions.Item label='Installment Amount'>
					{toLKR(installmentAmount)}
				</Descriptions.Item>
				<Descriptions.Item label='Interest Rate'>{interestRate}</Descriptions.Item>
				<Descriptions.Item label='Cheque Number'>
					{chequNumber ?? "N/A"}
				</Descriptions.Item>
				<Descriptions.Item label='Matured Day'>
					{maturedDay ? moment(maturedDay).format("YYYY-MM-DD h:mm:ss a") : "N/A"}
				</Descriptions.Item>
				<Descriptions.Item label='Guarantor I'>
					{renderGuarantorView(firstGuarantor)}
				</Descriptions.Item>
				<Descriptions.Item label='Guarantor II'>
					{renderGuarantorView(secondGuarantor)}
				</Descriptions.Item>
				<Descriptions.Item label='Note'>{note}</Descriptions.Item>
				{rejectReason && (
					<Descriptions.Item label='Rejected Reason'>
						{rejectReason}
					</Descriptions.Item>
				)}
				<Descriptions.Item label='Remaining Installment'>
					{ramainingInstallment}
				</Descriptions.Item>
				<Descriptions.Item label='Remaining Amount'>
					{toLKR(remainingAmount)}
				</Descriptions.Item>
			</Descriptions>
		);
	}

	return (
		<Spin spinning={busy}>
			<Table
				dataSource={data}
				size='small'
				expandable={{
					expandedRowRender: renderExtra,
				}}>
				<Table.Column title='Loan Id' dataIndex='id' />
				<Table.Column title='EPF Number' dataIndex='epfNo' />
				<Table.Column
					title='Guarantor I'
					dataIndex='gurantors'
					render={(val, _, __) => val[0].name ?? "N/A"}
				/>
				<Table.Column
					title='Guarantor II'
					dataIndex='gurantors'
					render={(val, _, __) => val[1].name ?? "N/A"}
				/>
				<Table.Column
					title='Loan Amount'
					dataIndex='loanAmount'
					render={(val, _, __) => toLKR(val)}
				/>
				<Table.Column
					title='Created On'
					dataIndex='createdOn'
					render={(val, _, __) => moment(val).format("YYYY-MM-DD h:mm:ss a")}
				/>
				<Table.Column
					title='Status'
					dataIndex='processingStatus'
					render={(val, _, __) => statusMap.get(val)?.title}
				/>
			</Table>
		</Spin>
	);
}

function Page() {
	return (
		<>
			<PageHeader title='Approve Loan (HR)' onBack={() => window.history.back()} />
			<Tabs defaultActiveKey='0' tabPosition='top'>
				<Tabs.TabPane tab='Pending Approvals' key='0'>
					<ApproveClaimByHR />
				</Tabs.TabPane>
				<Tabs.TabPane tab='Approval History' key='1'>
					<HistoryView />
				</Tabs.TabPane>
			</Tabs>
		</>
	);
}

export default Page;
