import React, { FunctionComponent } from "react";
import { Table, Tag, message, Spin, Drawer, Modal, Button, Input } from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { ClaimRepoActions } from "../repos/ClaimRepo";
// import logo from '../logo.png'
import "./style.css";
import { lionUserManager } from "../repos/UserRepo";
import { imgBaseUrl } from "../api.config";
import { bool, boolean } from "@hapi/joi";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
const { Column } = Table;
const { TextArea } = Input;

class ApproveLoanSettlement extends React.Component<any, any> {
	constructor(props: any) {
		super(props);

		this.state = {
			claims: [],
			loading: false,
			visibleView: false,
			reason: false,
			visible: false,
			id: "",
			filename: "",
		};
	}

	componentDidMount() {
		this._getData();
	}

	async _getData() {
		this.setState({ loading: true });
		await this.props.getLoanForSettelment();
		this.setState({ loading: false });
	}

	approve = async (data: any, reason: any, status: any) => {
		this.setState({ loading: true });

		var form = {
			settleStatus: status,
			reason: reason,
		};

		var res = await this.props.approveLoanSettlement(form, data);
		if (res) {
			this._getData();
			message.success("SUCCESSFULLY SUBMITTED");
		} else {
			message.error("SOMETHING WENT WRONG");
		}
	};

	view = (data: any) => {
		var result = this.props.ClaimRepo.aa.filter((obj: any) => {
			return obj.id === data;
		});
		console.log(result[0].settleDocument[0].fileName);
		this.setState({
			viewImage: true,
			fileName: result[0].settleDocument[0].fileName,
		});
	};

	viewDetails(id: any) {
		var result = this.state.claims.filter((obj: any) => {
			return obj.id === id;
		});

		this.setState({
			visibleView: true,
		});
	}

	onClose = () => {
		this.setState({
			visibleView: false,
		});
	};

	rejectClaim = (id: any) => {
		this.setState({
			visible: true,
			id: id,
		});
	};

	rejectedReasonTextChange = (reason: any) => {
		this.setState({
			reason: reason.target.value,
		});
	};

	submitReason = () => {
		if (this.state.reason == "") {
			message.error("Please Enter the Reason");
			return;
		}
		(async () => {
			await this.approve(this.state.id, this.state.reason, "11");
		})();

		this.setState({ visible: false });
	};

	handleCancel = (e: any) => {
		this.setState({
			visible: false,
			viewImage: false,
		});
	};
	render() {
		return (
			<div>
				<Spin
					size='small'
					tip='Data Loading'
					spinning={this.state.loading}
					delay={500}>
					<div style={{ opacity: this.state.loading ? 0.8 : 1 }}>
						<h3>Approve Loan Settlement</h3>

						<Table
							style={{ marginTop: 26, padding: 5 }}
							dataSource={(this.props.ClaimRepo.aa ?? []).reverse()}>
							<Column
								title='Loan Id'
								key='id'
								dataIndex='loan'
								render={(value: any, _, __) => value.id}
							/>
							{/* <Column width="100" title="Employee No" dataIndex="employeeId" key="employeeId" /> */}
							<Column
								width='100'
								title='EPF No'
								dataIndex='loan'
								key='epfNo'
								render={(value: any, _, __) => value.epfNo}
							/>
							<Column width='100' title='Employee Name' dataIndex='name' key='name' />
							<Column
								width='100'
								title='Loan Amount'
								dataIndex='loan'
								render={(value, item, index) =>
									"Rs. " +
									(Math.round(value.loanAmount * 100) / 100)
										.toFixed(2)
										.toString()
										.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
								}
							/>
							<Column
								title='Remaining Amount'
								dataIndex='loan'
								key='remainingAmount'
								render={(value, item, index) =>
									"Rs. " +
									(Math.round(value.remainingAmount * 100) / 100)
										.toFixed(2)
										.toString()
										.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
								}
							/>
							<Column
								title='Settlement Amount'
								dataIndex='installmentAmount'
								key='installmentAmount'
								render={(value, item, index) =>
									"Rs. " +
									(Math.round(value * 100) / 100)
										.toFixed(2)
										.toString()
										.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
								}
							/>
							<Column
								title='Action'
								key='id'
								render={(value, item, index) => (
									<div>
										<span>
											<a onClick={() => this.approve(value.id, "Approved", "2")}>
												<Tag color='#875ec4'> Approve</Tag>
											</a>
										</span>
										<span>
											<a onClick={() => this.view(value.id)}>
												<Tag color='#d6d909'> View Doc</Tag>
											</a>
										</span>
										<span>
											<a onClick={() => this.rejectClaim(value.id)}>
												<Tag color='#cd201f'> Reject</Tag>
											</a>
										</span>
									</div>
								)}
							/>
						</Table>
					</div>
				</Spin>

				<Modal
					title='Please Enter The Rejected Reason'
					visible={this.state.visible}
					onCancel={this.handleCancel}
					footer={[
						<Button key='submit' type='primary' onClick={this.submitReason}>
							Submit
						</Button>,
					]}>
					<TextArea
						rows={4}
						onChange={this.rejectedReasonTextChange}
						value={this.state.reason}
					/>
				</Modal>

				<Modal
					bodyStyle={{ backgroundColor: "transparent" }}
					width={650}
					mask={false}
					onCancel={this.handleCancel}
					visible={this.state.viewImage}
					footer={[]}>
					<div style={{ margin: "auto" }}>
						<Button href={imgBaseUrl + this.state.filename} style={{ margin: 10 }}>
							Download Image
						</Button>
						<TransformWrapper>
							<TransformComponent>
								<img
									style={{ minWidth: 600, maxWidth: 600 }}
									src={imgBaseUrl + this.state.fileName}
									alt='test'
								/>
							</TransformComponent>
						</TransformWrapper>
					</div>
				</Modal>
			</div>
		);
	}
}

const WrappedApproveClaim = ApproveLoanSettlement;
const withDashboardRepo = connect(
	(state: any) => state,
	ClaimRepoActions
)(WrappedApproveClaim);
export default withDashboardRepo;
