import React, { FunctionComponent, useState } from "react";
import {
	Table,
	Tag,
	message,
	Spin,
	Drawer,
	Modal,
	Button,
	Input,
	PageHeader,
	Row,
	Col,
	DatePicker,
	Menu,
	Dropdown,
} from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { ClaimRepoActions } from "../repos/ClaimRepo";
// import logo from '../logo.png'
import "./style.css";
import { lionUserManager } from "../repos/UserRepo";
import { bool, boolean } from "@hapi/joi";
import Select from "rc-select";
const { Column } = Table;
const { TextArea } = Input;

class ApproveClaim extends React.Component<any, any> {
	defaultFilter = (_value: any) => true;
	constructor(props: any) {
		super(props);

		this.state = {
			claims: [],
			loading: false,
			visibleView: false,
			reason: false,
			visible: false,
			filter: this.defaultFilter,
			filteredColumn: "",
			id: "",
		};
	}

	componentDidMount() {
		this._getData();
	}

	async _getData() {
		this.setState({ loading: true });
		await this.props.getAllClaims();

		var claimList: any[] = [];
		this.props.ClaimRepo.claims.forEach((element: any) => {
			var data = {
				...element,
				id: element.loan.id,
				employeeId: element.loan.employeeId,
				loanAmount: element.loan.loanAmount,
				note: element.loan.note,
				employeeName: element.name,
			};
			claimList.push(data);
		});
		this.setState({ claims: claimList, loading: false });
	}

	approve = async (data: any, reason: any, status: any) => {
		this.setState({ loading: true });
		const form = new FormData();
		form.append("employeiId", lionUserManager.user.data.USER_ID);
		form.append("approve", status);
		form.append("comment", reason);
		form.append("loanId", data);
		var res = await this.props.gurantorApproval(form);
		if (res) {
			this._getData();
			message.success("SUCCESSFULLY SUBMITTED");
		} else {
			message.error("SOMETHING WENT WRONG");
		}
	};

	viewDetails(id: any) {
		var result = this.state.claims.filter((obj: any) => {
			return obj.id === id;
		});

		this.setState({
			visibleView: true,
		});
	}

	onClose = () => {
		this.setState({
			visibleView: false,
		});
	};

	rejectClaim = (id: any) => {
		this.setState({
			visible: true,
			id: id,
		});
	};

	rejectedReasonTextChange = (reason: any) => {
		this.setState({
			reason: reason.target.value,
		});
	};

	submitReason = () => {
		if (this.state.reason == "") {
			message.error("Please Enter the Reason");
			return;
		}
		(async () => {
			await this.approve(this.state.id, this.state.reason, "0");
		})();

		this.setState({ visible: false });
	};

	handleCancel = (e: any) => {
		this.setState({
			visible: false,
		});
	};

	clearFilters = () => {
		this.setState({
			filteredColumn: null,
			filter: this.defaultFilter,
		});
	};

	render() {
		return (
			<div >
				<div style={{ marginTop: 15, marginBottom: 15 }}>
					<h3>
						<b>
							{" "}
							As a guarantor, I guarantee to settle any unsettled dues( Capital and interest) of the borrower, relating to the loan arising under any circumstances, to the lender ( Staff welfare Society – Lion Brewery).{" "}
						</b>
					</h3>
				</div>
				<PageHeader
					title='Guarantors Approval'
					onBack={() => window.history.back()}
				/>
				<Spin
					size='small'
					tip='Data Loading'
					spinning={this.state.loading}
					delay={500}>
					<div style={{ opacity: this.state.loading ? 0.8 : 1 }}>
						<Table dataSource={this.state.claims.reverse()}>
							<Column
								// filters={[
								// 	{
								// 		text: 'Joe',
        						// 		value: "0",
								// 	}
								// ]}
								// filterDropdown={
								// 	<TableColumnSlectorSearch
								// 		filterOnChange
								// 		options={statusCodes}
								// 		onFilter={(_value?: any) => {
								// 			if (_value)
								// 				this.setState({
								// 					filteredColumn: "claimId",
								// 					filter: (value: any) => {
								// 						console.log({ value });
								// 						return value.claimId.search(_value) !== -1;
								// 					},
								// 				});
								// 			else this.clearFilters();
								// 		}}
								// 		onReset={this.clearFilters}
								// 	/>
								// }
								// onFilter={(e, ee) => {console.log({e, ee}); return true}}
								title='Loan Id'
								dataIndex='id'
								render={(value, item, index) => value}
							/>
							{/* <Column
								width='100'
								title='Employee No'
								dataIndex='employeeId'
								key='employeeId'
							/> */}
							<Column
								width='100'
								title='EPF No'
								dataIndex='epfNumberOfLonaInitator'
								key='epfNo'
							/>
							<Column
								width='100'
								title="Requester's Name"
								dataIndex='lonaInitatorName'
								key='initatorName'
							/>
							<Column
								width='100'
								title="Guarantor's Name"
								dataIndex='employeeName'
								key='employeeName'
							/>
							<Column
								width='100'
								title='Loan Amount'
								dataIndex='loanAmount'
								render={(value, item, index) =>
									"Rs. " +
									(Math.round(value * 100) / 100)
										.toFixed(2)
										.toString()
										.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
								}
							/>
							<Column title='Note' dataIndex='note' key='note' />
							<Column
								title='Action'
								key='id'
								render={(value, item, index) => (
									<div>
										<span>
											<a onClick={() => this.approve(value.id, "Approved", "1")}>
												<Tag color='#875ec4'> Approve</Tag>
											</a>
										</span>
										<span>
											<a onClick={() => this.rejectClaim(value.id)}>
												<Tag color='#cd201f'> Reject</Tag>
											</a>
										</span>
									</div>
								)}
							/>
						</Table>
					</div>
				</Spin>

				<Modal
					title='Please Enter The Rejected Reason'
					visible={this.state.visible}
					onCancel={this.handleCancel}
					footer={[
						<Button key='submit' type='primary' onClick={this.submitReason}>
							Submit
						</Button>,
					]}>
					<TextArea
						rows={4}
						onChange={this.rejectedReasonTextChange}
						value={this.state.reason}
					/>
				</Modal>
			</div>
		);
	}
}

interface TableColumnSearchProps {
	onFilter: (value?: any) => void;
	onReset: () => void;
	filterOnChange?: boolean;
}

interface TableColumnSlectorSearchProps {
	onFilter: (value?: any) => void;
	onReset: () => void;
	filterOnChange?: boolean;
	options: TableColumnSlectorSearchOptionType[];
}

type TableColumnSlectorSearchOptionType = {
	title: string;
	meta?: any;
};

let statusCodes: TableColumnSlectorSearchOptionType[] = [
	{
		title: "PENDING",
	},
];

export function TableColumnSlectorSearch(props: TableColumnSlectorSearchProps) {
	const [value, setValue] = useState<any>();

	const handleSelect = (opt: TableColumnSlectorSearchOptionType) => {
		setValue(opt);
		props.onFilter(opt);
	};
	
	return (
		// <div style={{ padding: 5 }}>
		// 	<Row gutter={5}>
		// 		<Col>

		// 			{/* <Input
		// 				// allowClear
		// 				// options={[{
		// 				// 	label: "PENDING",
		// 				// 	value: "PENDING"
		// 				// }]}
		// 				// onChange={(_, option) => {
		// 				// 	setValue(option);
		// 				// 	if (props.filterOnChange) props.onFilter(option);
		// 				// }}
		// 				// value={value}
		// 			>
		// 				<Select.Option value="ss">ss</Select.Option>
		// 			</Select> */}
		// 		</Col>
		// 	</Row>
		// </div>
		<Menu>
			{props.options.map((opt) => (
				<Menu.Item onSelect={() => handleSelect(opt)}>{opt.title}</Menu.Item>
			))}
		</Menu>
	);
}

export function TableColumnTextSearch(props: TableColumnSearchProps) {
	const [value, setValue] = useState("");
	return (
		<div style={{ padding: 5 }}>
			<Row gutter={5}>
				<Col>
					<Input
						allowClear
						onChange={(e) => {
							setValue(e.target.value);
							if (props.filterOnChange) props.onFilter(e.target.value);
						}}
						value={value}
					/>
				</Col>
			</Row>
		</div>
	);
}

export function TableColumnDateRangeSearch(props: TableColumnSearchProps) {
	const [value, setValue] = useState<any>();
	return (
		<div style={{ padding: 5 }}>
			<Row gutter={5}>
				<Col>
					<DatePicker.RangePicker
						allowClear
						value={value}
						allowEmpty={[false, false]}
						onChange={(e) => {
							setValue(e);
							if (props.filterOnChange) props.onFilter(e);
						}}
					/>
					{/* <Input
						allowClear
						onChange={(e) => {
							setValue(e.target.value);
							if (props.filterOnChange) props.onFilter(e.target.value);
						}}
						value={value}
					/> */}
				</Col>
			</Row>
		</div>
	);
}

const WrappedApproveClaim = ApproveClaim;
const withDashboardRepo = connect(
	(state: any) => state,
	ClaimRepoActions
)(WrappedApproveClaim);
export default withDashboardRepo;
