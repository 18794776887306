import { RcFile } from 'antd/lib/upload/interface';
import { FuelGrade } from './Vehicle';

interface ClaimProps {
    exceededEngin: boolean
    liters: number
    pumpDate: string
    files: Array<RcFile>
    meterReading: number
    amount: number
    fuelGrade: FuelGrade
}

export class Claim implements ClaimProps {
    fuelGrade: FuelGrade
    exceededEngin: boolean
    liters: number
    pumpDate: string
    files: Array<RcFile>
    meterReading: number
    amount: number
    constructor(props: ClaimProps) {
        const {pumpDate, files, meterReading, amount, exceededEngin, liters, fuelGrade} = props;
        this.fuelGrade = fuelGrade 
        this.liters = liters
        this.pumpDate = pumpDate
        this.exceededEngin = exceededEngin
        this.files = files
        this.meterReading = meterReading
        this.amount = amount
    }
}

// export  class MinorVersions {
//     original_image: string; 
//     constructor(props: any) {
//         const { original_image } = props;
//         this.original_image = original_image; 
//     }
// }