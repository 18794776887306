import React, { FunctionComponent } from "react";
import {
	Table,
	Tag,
	message,
	Spin,
	Drawer,
	Modal,
	Row,
	Col,
	Avatar,
	Typography,
	Input,
	Empty,
	Button,
	Descriptions,
	Card,
	Result
} from "antd";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { ClaimRepoActions } from "../repos/ClaimRepo";
import { lionUserManager } from "../repos/UserRepo";
import { imgBaseUrl } from "../api.config";
import { UserOutlined, EyeOutlined } from "@ant-design/icons";

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
// import logo from '../logo.png'
import "./style.css";

const { Paragraph, Text } = Typography;
const { TextArea } = Input;

const { Column } = Table;

class ApproveClaimDetails extends React.Component<any, any> {
	constructor(props: any) {
		super(props);

		this.state = {
			claims: [],
			loading: false,
			visibleView: false,
			visible: false,
			id: 0,
			index: 0,
			viewImage: false,
			selectedClaim: {},
			reason: "",
			filename: "",
			user: { firstName: "", lastName: "" }
		};
	}

	componentDidMount() {
		this._getData();
	}

	submitReason = () => {
		if (this.state.reason == "") {
			message.error("Please Enter the Reason");
			return;
		}
		(async () => {
			await this.updateStatus(this.state.id, 3, this.state.index);
		})();

		this.setState({ visible: false });
	};

	handleCancel = () => {
		this.setState({ visible: false, viewImage: false });
	};

	async _getData() {
		this.setState({ loading: true });
		await this.props.getClaimById(this.props.match.params.id);
 

		this.setState({
			claims: this.props.ClaimRepo.viewClaim.fuelBill,
			loading: false,
			user: this.props.ClaimRepo.viewClaim.employeeDetails
		});
	}

	rejectClaim(id: any, index: any) {
		this.setState({ visible: true, id: id, index: index });
	}

	rejectedReasonTextChange = (reason: any) => {
		this.setState({
			reason: reason.target.value
		});
	};

	async updateStatus(id: any, status: any, index: any) {
		this.setState({ loading: true });
		var res = await this.props.updateClaimStatus(id, status, this.state.reason);
		const claims = [...this.state.claims];
		claims.splice(index, 1);
		if (res) {
			this.setState({
				claims: claims,
				reason: "",
				loading: false
			});
			message.success("Successfully Updated");
		} else {
			this.setState({ loading: false });
			message.error("Something Went Wrong");
		}
	}

	viewDetails = (id: any) => {
		this.setState({ loading: true });

		const claim = this.state.claims.find((o: { id: Number }) => o.id === id);

		this.setState({
			selectedClaim: claim,
			visibleView: true
		});

		this.setState({ loading: false });
	};

	onClose = () => {
		this.setState({
			visibleView: false,
			viewImage: false
		});
	};

	viewImage = (filename: any) => {
		this.setState({
			viewImage: true,
			filename: filename
		});
	};

	render() {
		const { ClaimDetails } = this.state.claims;

		return (
			<div>
				<Spin
					size='small'
					tip='Data Loading'
					spinning={this.state.loading}
					delay={500}>
					<div style={{ opacity: this.state.loading ? 0.1 : 1 }}>
						<Row>
							<Col span={1}>
								{/* <Avatar size={40} icon={<UserOutlined /> src={lionUserManager.user.data.User.imagePaths} /> */}
								<Avatar size={50} icon={<UserOutlined />} />
							</Col>
							<Col style={{ marginLeft: 10 }} span={15}>
								<Row>
									{this.state.user && (
										<Text style={{ fontSize: 18 }} strong>
											{this.state.user.firstName + " " + this.state.user.lastName}{" "}
										</Text>
									)}
									{/* <Text style={{ fontSize: 18, marginLeft: 3 }} > {lionUserManager.user.data.User.designationName} </Text> */}
								</Row>
								<Text style={{ fontSize: 14 }}>
									{" "}
									Emp No :{this.state.user.employeeNumber}{" "}
								</Text>
							</Col>
							<Col span={7}></Col>
						</Row>
						<div>
							{/* {JSON.stringify(this.state.claims.reverse())} */}
							<Table
								style={{ marginTop: 26, padding: 5 }}
								dataSource={this.state.claims}>
								<Column title='Id' key='No' render={(value, item, index) => value.id} />

								<Column
									title='Amount'
									dataIndex='amount'
									render={(value, item, index) =>
										"Rs. " +
										(Math.round(value * 100) / 100)
											.toFixed(2)
											.toString()
											.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
									}
								/>
								{/* <Column title="Plate Number"   render={(value, item, index) => (
                                 value.vehicalDetails.plateNumber  
                            )}/>  */}
								<Column
									title='Meter Reading'
									render={(value, item, index) => value.meterReading}
								/>
								<Column
									title='Pump Date'
									render={(value, item, index) =>
										value.pumpDate && value.pumpDate.split("T")[0]
									}
								/>

								<Column
									title='Action'
									key='index'
									render={(value, item, index) => (
										<div>
											<span>
												<a onClick={() => this.viewDetails(value.id)}>
													<Tag color='#ababab'> View Docs</Tag>
												</a>
											</span>
											<span>
												<a onClick={() => this.updateStatus(value.id, "2", index)}>
													<Tag color='#52c41a'> Approve</Tag>
												</a>
											</span>

											<span>
												<a onClick={() => this.rejectClaim(value.id, index)}>
													<Tag color='#cd201f'> Reject</Tag>
												</a>
											</span>
										</div>
									)}
								/>
							</Table>
						</div>

						<Modal
							title='Please Enter The Rejected Reason'
							visible={this.state.visible}
							onOk={this.submitReason}
							onCancel={this.handleCancel}
							footer={[
								<Button key='submit' type='primary' onClick={this.submitReason}>
									Submit
								</Button>
							]}>
							<TextArea
								rows={4}
								onChange={this.rejectedReasonTextChange}
								value={this.state.reason}
							/>
						</Modal>

						<Modal
							bodyStyle={{ backgroundColor: "transparent" }}
							width={650}
							mask={false}
							onCancel={this.handleCancel}
							visible={this.state.viewImage}
							footer={[]}>
							<div style={{ margin: "auto" }}>
								<Button href={imgBaseUrl + this.state.filename} style={{ margin: 10 }}>
									Download Image
								</Button>
								<TransformWrapper>
									<TransformComponent>
										<img
											style={{ minWidth: 600, maxWidth: 600 }}
											src={imgBaseUrl + this.state.filename}
											alt='test'
										/>
									</TransformComponent>
								</TransformWrapper>
							</div>
						</Modal>

						<Drawer
							width={460}
							closable={false}
							onClose={this.onClose}
							visible={this.state.visibleView}>
							<Result
								title='Claim Details'
								subTitle='Please check all the details before submit'>
								<div className='desc'>
									<Paragraph>
										<Descriptions>
											<Descriptions.Item label={"Total Amount"} span={24}>
												Rs. {this.state.selectedClaim.amount}
											</Descriptions.Item>
											<Descriptions.Item label={"Pump Date"} span={24}>
												{this.state.selectedClaim.pumpDate &&
													this.state.selectedClaim.pumpDate.split("T")[0]}
											</Descriptions.Item>
											<Descriptions.Item label={"Mater Reading"} span={24}>
												{this.state.selectedClaim.meterReading}
											</Descriptions.Item>
											<Descriptions.Item label={"Fuel Grade"} span={24}>
												{this.state.selectedClaim.fuelGrade &&
													this.state.selectedClaim.fuelGrade.name}
											</Descriptions.Item>
										</Descriptions>
									</Paragraph>
								</div>
							</Result>

							<h3>Related attachments</h3>
							{/* {JSON.stringify(this.state.selectedClaim)} */}

							<Row>
								{this.state.selectedClaim.billDocuments &&
								this.state.selectedClaim.billDocuments.length > 0 ? (
									this.state.selectedClaim.billDocuments.map((doc: any) => (
										//   <Avatar style={{margin : 10  }} size={160} shape="square" src={imgBaseUrl+doc.fileName }/>
										<Row style={{ marginBottom: 10 }} gutter={10}>
											<Col
												onClick={() => this.viewImage(doc.fileName)}
												style={{
													height: 100,
													width: 100,
													display: "flex",
													justifyContent: "center",
													alignItems: "center"
												}}>
												<img src={imgBaseUrl + doc.fileName} style={{ width: "100%" }} />
												<EyeOutlined
													style={{
														position: "absolute",
														color: "white",
														padding: 5,
														borderRadius: "100%",
														background: "#00000070"
													}}
												/>
											</Col>
										</Row>
									))
								) : (
									<Empty style={{ marginTop: 30, marginLeft: 100 }} />
								)}
							</Row>
						</Drawer>
					</div>
				</Spin>
			</div>
		);
	}
}

const WrappedApproveClaimDetails = ApproveClaimDetails;
const withDashboardRepo = connect(
	(state: any) => state,
	ClaimRepoActions
)(WrappedApproveClaimDetails);
export default withRouter(withDashboardRepo as FunctionComponent);
