import React, {
	FunctionComponent,
	LegacyRef,
	createRef,
	RefObject,
} from "react";
import {
	Spin,
	Card,
	Typography,
	Row,
	Button,
	Descriptions,
	Avatar,
	Col,
	Badge,
	Modal,
	message,
} from "antd";
import { Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { ClaimRepoActions } from "../repos/ClaimRepo";
// import logo from '../logo.png'
import { imgBaseUrl } from "../api.config";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import "./style.css";
import { EditClaimForm } from "./EditClaim";
import { Vehicle } from "../repos/models/Vehicle";
import { Claim } from "../repos/models/Claim";
import { VehicleRepoActions } from "../repos/VehicleRepo";
import moment from "moment";
import { RcFile } from "antd/lib/upload";
import textHide from "@antv/g2plot/lib/util/responsive/rules/text-hide";
import { lionUserManager } from "../repos/UserRepo";
import ReactToPrint from "react-to-print";
import { PrinterOutlined } from "@ant-design/icons";
import { from } from "rxjs";
import PrintComponent from "../components/PrintComponent";
const { Paragraph, Text, Title } = Typography;

var totalClaims: any;
var approvedClaims: any;
var pendingClaims: any;
var rejectedClaims: any;
const state_value = ["DRAFT", "PENDING", "APPROVED", "REJECTED", "DISABLED"];

const color = ["#0d52d0", "#faad14", "#52c41a", "#d9363e", "#0d52d0"];

class ViewClaims extends React.Component<any, any> {
	// componentRef: RefObject<any>
	componentRef: PrintComponent | null;
	constructor(props: any) {
		super(props);
		this.componentRef = null;
		// this.componentRef = React.createRef()
		this.state = {
			selectedClaim: null,
			formVisibility: false,
			claim: {},
			loading: false,
			totalClaims: 0,
			pendingClaims: 0,
			rejectedClaims: 0,
			approvedClaims: 0,
			visibleView: false,
			viewImage: false,
			visible: false,
			status: 10,
		};
	}

	componentDidMount() {
		this._getData();
	}

	async _getData() {
		this.setState({ loading: true });
		await this.props.getAllClaimDetailsById(this.props.match.params.id);
		await this.props.getAllEligibleFuelGrades();
		totalClaims = this.props.ClaimRepo.allClaimDetails.fuelBill.length;
		pendingClaims = this.props.ClaimRepo.allClaimDetails.fuelBill.filter(
			(obj: any) => obj.status === 1
		).length;
		rejectedClaims = this.props.ClaimRepo.allClaimDetails.fuelBill.filter(
			(obj: any) => obj.status === 3
		).length;
		approvedClaims = this.props.ClaimRepo.allClaimDetails.fuelBill.filter(
			(obj: any) => obj.status === 2
		).length;

		this.setState({
			loading: false,
			claim: this.props.ClaimRepo.allClaimDetails,
			totalClaims: totalClaims,
			pendingClaims: pendingClaims,
			rejectedClaims,
			approvedClaims,
			status: this.props.ClaimRepo.allClaimDetails.status,
		});
	}

	handleOk = (e: any) => {
		console.log(e);
		this.setState({
			visible: false,
		});
	};

	handleCancle = (e: any) => {
		console.log(e);
		this.setState({
			visible: false,
		});
	};

	viewImage = (filename: any) => {
		this.setState({
			visible: true,
			filename: filename,
		});
	};

	async onEditFormDone(claim: any) {
		try {
			await this.editClaim(claim);
			this.setState({
				formVisibility: false,
			});
			// this.props.location.reload()
			this.props.history.go();
			return true;
		} catch (err) {
			return false;
		}
	}

	async editClaim(claim: any) {
		var hide;
		try {
			const _form = new FormData();
			hide = message.loading("Action in progress..", 0);

			console.log(claim);

			const body = {
				fuelClaim: { id: this.props.match.params.id },
				employeeId: this.state.selectedClaim.employeeId,
				amount: claim.amount,
				pumpDate: claim.pumpDate,
				fuelGrade: { id: claim.fuelGrade.id },
				meterReading: claim.meterReading,
				liters: claim.liters,
				exceededEngin: claim.exceededEngin,
				billDocuments: [
					...claim.files.map((file: RcFile) => ({
						fileName: file.name,
						documentType: 1,
					})),
					...claim.externalAttachments.map((file: any) => ({
						fileName: file.name,
						documentType: file.type,
					})),
				],
			};

			if (body.billDocuments && body.billDocuments.length < 1) {
				throw new Error("Attachments not found");
			}

			_form.append("fuelClaim", JSON.stringify(body));

			claim.files.forEach((file: RcFile) => {
				_form.append("files", file);
			});

			var response = await lionUserManager.authenticationManager.post(
				`/fuelClaim/FuelBill/UpdateDrafModeBillItem/${this.state.selectedClaim.id}`,
				_form
			);

			if (response.status == 200) {
				message.success(`Successfully Created`);
			} else throw new Error(response.message);
		} catch (err) {
			message.error((err as Error).message);
			throw err;
		} finally {
			hide && hide();
		}
	}

	async clickSendToHR(id: any) {
		var hide;
		try {
			hide = message.loading("Please wait");
			await lionUserManager.authenticationManager.post(
				`/fuelClaim/FuelClaim/SendToApprove/${id}`,
				{}
			);
			message.success("Done");
			this.props.history.go();
		} catch (err) {
			message.error("Something went wrong");
		} finally {
			hide && hide();
		}
	}

	async clickResubmit(id: any) {
		var hide;
		try {
			hide = message.loading("Please wait");
			const res = await lionUserManager.authenticationManager.post(
				`/fuelClaim/FuelClaim/ResubmitFuelClaim`,
				{
					id: id,
				}
			);
			message.success("Done");
			this.props.history.push(`/protected/view-claim/` + res.data.id);
			window.location.reload();
		} catch (err) {
			message.error("Something went wrong");
		} finally {
			hide && hide();
		}
	}

	render() {
		const { claim } = this.state.claim;

		return (
			<div>
				<Spin
					size='small'
					tip='Data Loading'
					spinning={this.state.loading}
					delay={500}>
					<Row justify='end'>
						<Col>
							{this.state.claim.status == 3 ? (
								<Button
									onClick={() => this.clickResubmit(this.state.claim.id)}
									style={{ marginTop: 24 }}
									type='primary'>
									Resubmit Claim
								</Button>
							) : (
								<div />
							)}
							{this.state.claim.status == 0 ? (
								<Button
									onClick={() => this.clickSendToHR(this.state.claim.id)}
									style={{ marginTop: 24 }}
									type='primary'>
									Send To HR Approval
								</Button>
							) : (
								<div />
							)}
							{this.state.claim.status == 1 && (
								<ReactToPrint
									trigger={() => (
										<Button type='default' style={{ marginLeft: 5 }}>
											<PrinterOutlined /> Print
										</Button>
									)}
									content={() => this.componentRef}></ReactToPrint>
							)}
						</Col>
					</Row>
					{
						<div style={{ opacity: this.state.loading ? 0.8 : 1 }}>
							{/* {JSON.stringify(this.state.claim)} */}
							<Descriptions bordered title='Claim Summary' size={"small"}>
								<Descriptions.Item label='Claim Id'>
									{this.state.claim.id}
								</Descriptions.Item>
								<Descriptions.Item label='Total Amount'>
									{" "}
									Rs.{" "}
									{(Math.round(this.state.claim.totalAmount * 100) / 100)
										.toFixed(2)
										.toString()
										.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
								</Descriptions.Item>
								<Descriptions.Item label='No Of Claims'>
									{this.state.totalClaims}
								</Descriptions.Item>
								<Descriptions.Item label='Pending Claims'>
									{this.state.pendingClaims}
								</Descriptions.Item>
								<Descriptions.Item label='Approved Claims'>
									{this.state.approvedClaims}
								</Descriptions.Item>
								<Descriptions.Item label='Rejected Claims'>
									{this.state.rejectedClaims}
								</Descriptions.Item>
								<Descriptions.Item label='Vehicle'>
									{this.state.claim.vehicalDetails &&
										this.state.claim.vehicalDetails.plateNumber}
								</Descriptions.Item>
							</Descriptions>
							<div style={{ marginTop: 24 }}>
								<Title
									style={{
										fontSize: 15,
										marginTop: 24,
										fontWeight: "bold",
										color: "#000",
									}}>
									Claim Details
								</Title>
							</div>
							<Row>
								{this.state.claim.fuelBill &&
									this.state.claim.fuelBill.map((key: any, i: number) => (
										<Card key={i} style={{ width: 340, margin: 10 }}>
											<Row>
												<Col span='20'>
													<Text strong style={{ fontSize: 15 }}>
														Id : 00{key.id}
													</Text>
												</Col>

												<Col
													span='4'
													style={{ display: "flex", justifyContent: "flex-end" }}>
													<Badge
														count={state_value[key.status]}
														style={{ backgroundColor: color[key.status] }}
													/>
												</Col>
											</Row>
											{/* <div className="desc" style={{ marginTop: 15 }}>
                    <Paragraph style={{ padding: 3 }}>
                      <Descriptions size="small" column={3}>
                        {key.pumpDetails && <Descriptions.Item  span={24}  label="Invoice Date">{key.pumpDetails.pumpDate.split("T")[0]}</Descriptions.Item>}
                        {key.pumpDetails && <Descriptions.Item span={24} label="Meter Reading">{key.pumpDetails.meterReading}</Descriptions.Item>}
                        <Descriptions.Item span={24} label="Amount"> Rs. {(Math.round(key.amount * 100) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Descriptions.Item>
                      </Descriptions>
                    </Paragraph>
                  </div> */}
											<Descriptions size='small' column={3}>
												<Descriptions.Item span={24} label='Amount'>
													{" "}
													Rs.{" "}
													{(Math.round(key.amount * 100) / 100)
														.toFixed(2)
														.toString()
														.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
												</Descriptions.Item>
												<Descriptions.Item span={24} label='Pump Date'>
													{key.pumpDate.split("T")[0]}
												</Descriptions.Item>
												<Descriptions.Item span={24} label='Liters'>
													{key.liters} L
												</Descriptions.Item>
												<Descriptions.Item span={24} label='Fuel Grade'>
													{key.fuelGrade.name && key.fuelGrade.name}
												</Descriptions.Item>
												{key.status == 3 && (
													<Descriptions.Item span={24}>
														{" "}
														<p style={{ color: "#d9363e", fontWeight: "bold" }}>
															{" "}
															Rejected Reason : {key.reason}{" "}
														</p>
													</Descriptions.Item>
												)}
											</Descriptions>
											{/* {JSON.stringify(key)} */}
											<div style={{ marginTop: 15 }}>
												<h5>Related Documents</h5>
												<Row>
													{key.billDocuments &&
														key.billDocuments.map((doc: any) => (
															<a onClick={() => this.viewImage(doc.fileName)}>
																<Avatar
																	src={imgBaseUrl + doc.fileName}
																	size={64}
																	style={{ margin: 2 }}
																/>
															</a>
														))}
												</Row>
												{/* bill-status: {key.status}
                    claim-status: {this.state.status} */}
												{key.status != 2 && this.state.status == 0 && (
													<Button
														style={{ float: "right" }}
														onClick={() =>
															this.setState({ formVisibility: true, selectedClaim: key })
														}>
														Edit
													</Button>
												)}
											</div>
										</Card>
									))}
							</Row>

							<div style={{ display: "none" }}>
								<PrintComponent
									claim={this.state.claim}
									ref={(el) => (this.componentRef = el)}
								/>
							</div>
						</div>
					}
				</Spin>
				{this.state.formVisibility && this.state.selectedClaim && (
					<EditClaimForm
						pumpDate={this.state.selectedClaim.pumpDate}
						literAmount={this.state.selectedClaim.liters}
						amount={this.state.selectedClaim.amount}
						attachments={this.state.selectedClaim.billDocuments.map((file: any) => ({
							id: file.id,
							name: file.fileName,
							type: file.documentType,
						}))}
						meterReading={parseInt(this.state.selectedClaim.meterReading)}
						title={`Edit Claim (Id : 00${this.state.selectedClaim.id})`}
						vehicle={{ id: this.state.claim.vehicalDetails.id }}
						defaultFuelGrade={this.state.selectedClaim.fuelGrade}
						fuelGrades={this.props.VehicleRepo.allEligibleFuelGrades}
						onCancel={() => this.setState({ formVisibility: false })}
						onDone={this.onEditFormDone.bind(this)}
						isVisible={this.state.formVisibility}
					/>
				)}

				<Modal
					width={650}
					visible={this.state.visible}
					onOk={this.handleOk}
					onCancel={this.handleCancle}
					footer={[]}>
					<div style={{ margin: "auto" }}>
						<Button href={imgBaseUrl + this.state.filename} style={{ margin: 10 }}>
							Download Image
						</Button>
						<TransformWrapper>
							<TransformComponent>
								<img
									style={{ minWidth: 600, maxWidth: 600 }}
									src={imgBaseUrl + this.state.filename}
									alt='test'
								/>
							</TransformComponent>
						</TransformWrapper>
					</div>
				</Modal>
			</div>
		);
	}
}

const WrappedViewClaims = ViewClaims;

const withClaimRepo = connect(
	(state: any) => state,
	ClaimRepoActions
)(WrappedViewClaims);
const withVehicleRepo = connect(
	(state: any) => state,
	VehicleRepoActions
)(withClaimRepo);
export default withRouter(withVehicleRepo as FunctionComponent);
