import React from 'react';
import { Row, Descriptions, Table, Col } from 'antd'
const { Column } = Table
interface ArtworkCardProps {
    claim: any,
}

export default class PrintComponent extends React.Component<ArtworkCardProps> {
    render() {
        const { claim } = this.props
        if (claim && claim.fuelBill) return (
            <div>
                <Col span={24}>
                    <Descriptions bordered title="Claim Summary" size={"small"}>
                        <Descriptions.Item label="Claim Id">{claim.id}</Descriptions.Item>
                        <Descriptions.Item label="Total Amount">  Rs. {(Math.round(claim.totalAmount * 100) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</Descriptions.Item>
                        <Descriptions.Item label="No Of Claims">{claim.fuelBill.length}</Descriptions.Item>
                        <Descriptions.Item label="Pending Claims">{claim.fuelBill.filter((obj: any) => obj.status === 1).length}</Descriptions.Item>
                        <Descriptions.Item label="Approved Claims">{claim.fuelBill.filter((obj: any) => obj.status === 2).length}</Descriptions.Item>
                        <Descriptions.Item label="Rejected Claims">{claim.fuelBill.filter((obj: any) => obj.status === 3).length}</Descriptions.Item>
                        <Descriptions.Item label="Vehicle">{claim.vehicalDetails.plateNumber}</Descriptions.Item>
                    </Descriptions>
                </Col>

                <Col span={24}>
                    <Table pagination={false} style={{ marginTop: 26, padding: 5 }} dataSource={claim.fuelBill.reverse()}>
                        <Column title="Amount" key="amount" render={(value, item, index) => `Rs. ${(Math.round(value.amount * 100) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}/>
                        <Column title="Pump Date" key="pumpDate" render={(value, item, index) => value.pumpDate.split("T")[0]}/>
                        <Column title="Liters" key="liters" render={(value, item, index) => `${value.liters} L`} />
                        <Column title="Fuel Grade" key="fuelGrade" render={(value, item, index) => value.fuelGrade.name}/>
                    </Table>
                </Col>
            </div >
        );
        return (<div></div>)
    }
}