import React, { FunctionComponent, Component, createRef } from "react";
import {
	Table,
	Tag,
	message,
	Spin,
	Drawer,
	Modal,
	Button,
	Input,
	Row,
	Col,
} from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { ClaimRepoActions } from "../../repos/ClaimRepo";
// import logo from '../logo.png'
import "../style.css";
import ComponentToPrint from "../../components/ComponentToPrint";
import { RouteComponentProps } from "react-router-dom";
import ReactToPrint from "react-to-print";
import { getStatusByCode, statusMap } from "../statusMapper";
import { PritableCurrentLoansList } from "./PrintableCurrentLoansList";
const { Column } = Table;
const { TextArea } = Input;
const state_value = [
	"DRAFT",
	"PENDING",
	"GUARANTOR APPROVED ",
	"HR APPROVED",
	"HEAD OF HR APPROVED",
	"HEAD OF WELFARE APPROVED",
	"REJECTED",
	"RESUBMITED",
	"ACTIVE",
	"LOAN SETTLED",
	"APPLIED FOR SETTLEMENT",
	"SETTLEMENT REJECTED",
];

class CurrentLoansList extends React.Component<any, any> {
	tableRef: React.RefObject<HTMLDivElement>;
	constructor(props: any) {
		super(props);
		this.tableRef = createRef();
		this.state = {
			claims: [],
			loading: false,
			visibleView: false,
			reason: false,
			visible: false,
			cheque: "",
			id: "",
		};
	}

	componentDidMount() {
		this._getData();
	}

	async _getData() {
		this.setState({ loading: true });
		const form = new FormData();
		form.append("startDate", "");
		form.append("endDate", "");
		await this.props.getCurrentLoanList(form);

		console.log(this.props.ClaimRepo.loanList);
		this.setState({ claims: this.props.ClaimRepo.loanList, loading: false });
	}

	viewDetails(id: any) {
		var result = this.state.claims.filter((obj: any) => {
			return obj.id === id;
		});

		this.setState({
			visibleView: true,
		});
	}

	onClose = () => {
		this.setState({
			visibleView: false,
		});
	};

	handleCancel = (e: any) => {
		this.setState({
			visible: false,
		});
	};

	render() {
		return (
			<div>
				<Spin
					size='small'
					tip='Data Loading'
					spinning={this.state.loading}
					delay={500}>
					<div style={{ opacity: this.state.loading ? 0.8 : 1 }}>
						<Row>
							<Col span={22}>
								<h3>Current Active Loan List</h3>
							</Col>
							<div style={{}}>
								<ReactToPrint
									trigger={() => {
										// NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
										// to the root node of the returned component as it will be overwritten.
										return (
											<Button href='#' type='default'>
												Print this
											</Button>
										);
									}}
									content={() => this.tableRef.current}
								/>
							</div>
						</Row>
						<PritableCurrentLoansList ref={this.tableRef} dataSource={this.state.claims} hidden/>
						<Row gutter={10}>
							<Col span='24' style={{ marginTop: 15 }}>
								<Col className='row-layout-claim-detail'>
									<Table
										style={{ marginTop: 26, padding: 5 }}
										dataSource={this.state.claims}>
										<Column
											title='Loan Id'
											dataIndex='id'
											render={(value, item, index) => value}
										/>
										<Column width='100' title='Epf No' dataIndex='epfNo' />
										<Column
											width='100'
											title='Name'
											dataIndex='name'
											render={(value, item, index) => value}
										/>
										{/* <Column
											width='100'
											title='Note'
											dataIndex='note'
											render={(value, item, index) => value}
										/> */}

										<Column
											title='Loan Amount'
											dataIndex='loanAmount'
											render={(value, item, index) =>
												"Rs. " +
												(Math.round(value * 100) / 100)
													.toFixed(2)
													.toString()
													.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
											}
										/>
										<Column
											title='Outstanding Amount'
											dataIndex='remainingAmount'
											render={(value, item, index) =>
												"Rs. " +
												(Math.round(value * 100) / 100)
													.toFixed(2)
													.toString()
													.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
											}
										/>
										<Column
											title='Loan Period'
											dataIndex='loanPeriod'
											render={(value, item, index) => value}
										/>
										<Column
											filters={Array.from(statusMap.entries()).map(([value, { title }]) => ({
												text: title,
												value: value.toString(),
											}))}
											onFilter={(data, record: any) => {
												return record.processingStatus == data;
											}}
											title='Status'
											width={200}
											dataIndex='processingStatus'
											render={(value, item, index) =>
												getStatusByCode(parseInt(value))?.title
											}
										/>
										<Column
											title='Date'
											dataIndex='createdOn'
											render={(value, item, index) => value.split("T")[0]}
										/>
									</Table>
								</Col>
							</Col>
						</Row>
					</div>
				</Spin>
			</div>
		);
	}
}

const WrappedCurrentLoansList = CurrentLoansList;
const withDashboardRepo = connect(
	(state: any) => state,
	ClaimRepoActions
)(WrappedCurrentLoansList);
export default withDashboardRepo;
