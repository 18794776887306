export class RouteConfig{
    static root = '/'

    static PROTECTED = '/protected'
    static PROTECTED_ROOT = '/protected/'
    static PROTECTED_MAIN_DASHBOARD = '/protected/dashboard'
    static PROTECTED_HOME = '/protected/home'
    static PROTECTED_CREATE = '/protected/create'
    static PROTECTED_REGISTER_VEHICLE = '/protected/register'
    static PROTECTED_VIEW_CLAIM = '/protected/view-loan/:id'
    static PROTECTED_EDIT_CLAIM = '/protected/edit-loan'
    static PROTECTED_VIEW_ALL_CLAIM = '/protected/all-claims' 
    static PROTECTED_APPROVE_CLAIM = '/protected/approve-loan'
    static PROTECTED_APPROVE_CLAIM_BY_HR = '/protected/approve-loan-by-hr'
    static PROTECTED_APPROVE_CLAIM_BY_HR_HEAD = '/protected/approve-loan-by-hr-head'
    static PROTECTED_APPROVE_CLAIM_BY_WELFARE = '/protected/approve-loan-by-welfare_department'
    static PROTECTED_CLOSE_REQUEST = '/protected/close_loan_request'
    static PROTECTED_APPROVE_LOAN_SETTLEMENT = '/protected/ápprove_loan_settlement'
    static PROTECTED_LOAN_SETTLEMENT = '/protected/loan_settlement'
    static PROTECTED_CURRENT_LOAN_LIST_REPORT = '/protected/current-loan-list'
	static PROTECTED_LOAN_DETAILS_REPORT = '/protected/loan-details-report'
	static PROTECTED_DOCUMENTS = '/protected/documents'

    static LOGIN = '/'
}

//this applies to breadcrumbs
export const rootComponent = "Home"
export const rootIcon = "home"

