import React, { FunctionComponent } from "react";
import {
	Empty,
	Divider,
	Button,
	Typography,
	Row,
	Descriptions,
	Col,
	Popconfirm,
	Spin,
	Table,
	Tag,
	Input,
	DatePicker,
	Drawer,
	Form,
	Modal,
	Select,
	message,
} from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { UserRepoActions } from "../repos/UserRepo";
import { CarOutlined, FileTextOutlined } from "@ant-design/icons";
import { Elements } from "../components/Dashboard";
import { DashboardActions } from "../repos/DashboardRepo";
import { lionUserManager } from "../repos/UserRepo";
import {
	UploaderState,
	FileDataMapper,
	MultipleFileUploader,
	defaultImageExtensions,
} from "../components";
import "./style.css";
import { async } from "rxjs/internal/scheduler/async";
import { getStatusByCode } from "./statusMapper";
const { Column } = Table;
const moment = require("moment");
const { TextArea } = Input;
const smallCardMediaSpans = {
	xl: 6,
	xs: 24,
	sm: 24,
	lg: 12,
};

const smallClaimCardMediaSpans = {
	xl: 7,
	xs: 24,
	sm: 24,
	lg: 12,
};

const layout = {
	labelCol: {
		span: 8,
	},
	wrapperCol: {
		span: 16,
	},
};

const layout1 = {
	labelCol: {
		span: 8,
	},
	wrapperCol: {
		span: 16,
	},
};
const getColor = (i: number) => {
	switch (i) {
		case 0:
			return "#4C56BA";
		case 1:
			return "#29275F";
		case 2:
			return "#F64B53";
		case 3:
			return "#929CB7";
		default:
			return "#0B3C49";
	}
};

// const state_value = ["DRAFT", "PENDING", " DISCUSSED",]

// const color = ["#0d52d0", "#faad14", "#52c41a"]

// const state_value = [
// 	"DRAFT",
// 	"PENDING",
// 	"GUARANTOR APPROVED ",
// 	"HR APPROVED",
// 	"HEAD OF HR APPROVED",
// 	// "HEAD OF WELFARE APPROVED"
// 	"CHEQUE IS PROCESSING",
// 	"REJECTED",
// 	"RESUBMITED",
// 	"ACTIVE",
// 	"LOAN SETTLED",
// 	"APPLIED FOR SETTLEMENT",
// 	"SETTLEMENT REJECTED",
// ];

// const color = [
// 	"#0d52d0",
// 	"#faad14",
// 	"#52c41a",
// 	"#d9363e",
// 	"#0d52d0",
// 	"#52c41a",
// 	"#d9363e",
// 	"#0d52d0",
// ];
// const data = [
// 	{
// 		title: "Ant Design Title 1",
// 	},
// 	{
// 		title: "Ant Design Title 2",
// 	},
// 	{
// 		title: "Ant Design Title 3",
// 	},
// 	{
// 		title: "Ant Design Title 4",
// 	},
// ];

let bar: any = null;
class Dashboard extends React.Component<any, any> {
	myRef: React.RefObject<any>;
	constructor(props: any) {
		super(props);
		this.myRef = React.createRef();
		this.state = {
			value: 3,
			historyData: [],
			vehicleData: {},
			loading: false,
			claims: [],
			visible: false,
			selectedClaim: {},
			editClaim: false,
			departments: [],
			firstGuarantor: [],
			secondGuarantor: [],
			gurantors: [],
			gurantor01: "",
			gurantor02: "",
			department01: "",
			department02: "",
			note: "",
			departmentName01: "",
			departmentName02: "",
			firstGuarantorId: "",
			secondGuarantorId: "",
			firstGuarantorDepId: "",
			secondGuarantorDepId: "",
			amount: "",
			syncStructureLevelCode_01: "",
			syncStructureLevelCode_02: "",
			description: "",
			invoiceNo: "",
			claimHistory: [],
			fileMapperData: {
				files: [],
				data: [],
			},
			isVisibleSettlementModal: false,
			outstanding_amount: "",
		};
	}

	click(String: any) {}

	componentDidMount() {
		this.setState({ loading: true });
		this._getData();
	}

	async _getData() {
		try {
			this.setState({ loading: true });
			await this.props.getSummaryData();
			await this.props.getUserClaimData();
			await this.props.getDepartment();
			console.log(this.props.DashboardRepo.userClaimDetails);
			this.setState({
				departments: this.props.ClaimRepo.departments,
				historyData: this.props.DashboardRepo.userHistory,
				loading: false,
				claims: this.props.DashboardRepo.userClaimDetails.reverse(),
			});
		} catch (err) {}
	}

	settleLoan = async (e: any) => {
		var loanDetails = this.state.claims.find(
			(claim: { id: any }) => claim.id === e
		);
		if (loanDetails.processingStatus == 8 || loanDetails.processingStatus == 11) {
			await this.setState({
				visible: false,
				outstanding_amount: loanDetails.remainingAmount,
				selectedClaim: loanDetails,
				isVisibleSettlementModal: true,
			});
		} else {
			message.error("You Cannot Settle This Loan");
			return;
		}
	};

	viewDetails = async (e: any) => {
		var loanDetails = this.state.claims.find(
			(claim: { id: any }) => claim.id === e
		);
		await this.setState({
			gurantors: loanDetails.gurantors,
			gurantor01: loanDetails.gurantors[0].name,
			gurantor02: loanDetails.gurantors[1].name,
			firstGuarantorId: loanDetails.gurantors[0].approvedBy,
			secondGuarantorId: loanDetails.gurantors[1].approvedBy,
			firstGuarantorDepId: loanDetails.gurantors[0].departmentId,
			secondGuarantorDepId: loanDetails.gurantors[1].departmentId,
			department01: loanDetails.gurantors[0].departmentName,
			department02: loanDetails.gurantors[1].departmentName,
			departmentName01: loanDetails.gurantors[0].departmentName,
			departmentName02: loanDetails.gurantors[1].departmentName,
			syncStructureLevelCode_01: loanDetails.gurantors[0].syncStructureLevelCode,
			syncStructureLevelCode_02: loanDetails.gurantors[1].syncStructureLevelCode,
			selectedClaim: loanDetails,
			note: loanDetails.note,
			visible: true,
		});

		this.getDepartmentData(loanDetails.gurantors[0].departmentId);
		this.GuarantorTwoDepartmentData(loanDetails.gurantors[1].departmentId);
	};

	onClose = () => {
		this.setState({
			visible: false,
			editClaim: false,
		});
	};

	edit = () => {
		this.setState({
			editClaim: true,
		});
	};

	view = () => {
		this.setState({
			editClaim: false,
		});
	};

	departmentChange = async (departmentId: any) => {
		await this.props.getGuarantor(departmentId);

		this.setState({
			department01: departmentId,
			firstGuarantorDepId: departmentId,
			firstGuarantorId: this.props.ClaimRepo.guarantors[0].id,
			firstGuarantor: this.props.ClaimRepo.guarantors,
		});
	};

	GuarantorTwodepartmentChange = async (departmentId: any) => {
		await this.props.getGuarantor(departmentId);
		this.setState({
			department02: departmentId,
			secondGuarantorDepId: departmentId,
			secondGuarantorId: this.props.ClaimRepo.guarantors[0].id,
			secondGuarantor: this.props.ClaimRepo.guarantors,
		});
	};

	getDepartmentData = async (departmentId: any) => {
		await this.props.getGuarantor(departmentId);

		this.setState({
			department01: departmentId,
			firstGuarantorDepId: departmentId,
			firstGuarantor: this.props.ClaimRepo.guarantors,
		});
	};

	GuarantorTwoDepartmentData = async (departmentId: any) => {
		await this.props.getGuarantor(departmentId);
		this.setState({
			department02: departmentId,
			secondGuarantorDepId: departmentId,
			secondGuarantor: this.props.ClaimRepo.guarantors,
		});
	};

	firstGuarantorChange = (id: any) => {
		this.setState({
			firstGuarantorId: id,
		});
	};

	secondGuarantorChange = (id: any) => {
		this.setState({
			secondGuarantorId: id,
		});
	};

	sendToApproval = async (id: any) => {
		this.setState({ loading: true, visible: false });
		var res = await this.props.sendToApproval(id);

		if (res) {
			this._getData();
		} else {
			message.error("Error");
		}

		this.setState({ loading: true });
	};

	deleteLoanRequest = async (id: any) => {
		var res = await this.props.deleteLoanRequest(id);
		if (res) {
			this.setState({
				visible: false,
			});
			this._getData();
		} else {
			message.error("Error");
		}
	};

	resubmitClaim = async (id: any) => {
		this.setState({ loading: true, visible: false });
		var res = await this.props.resubmitClaim(id);
		if (res) {
			this._getData();
		} else {
			message.error("Error");
		}
	};

	editLoan = async (id: any) => {
		if (this.state.firstGuarantorId == this.state.secondGuarantorId) {
			message.error("You Cannot use the same Guarantor");
			return;
		}

		this.setState({
			visible: false,
			loading: true,
		});

		var data = {
			employeeId: lionUserManager.user.data.USER_ID,
			actveStatus: 1,
			note: this.state.note,
			processingStatus: 1,
			gurantors: [
				{
					approvedBy: this.state.firstGuarantorId,
					departmentId: this.state.firstGuarantorDepId,
				},
				{
					approvedBy: this.state.secondGuarantorId,
					departmentId: this.state.secondGuarantorDepId,
				},
			],
		};

		const form = new FormData();
		form.append("loan", JSON.stringify(data));
		var res = await this.props.editLoan(form, id);

		if (res) {
			window.location.reload();
		} else {
			message.error("Please try again");
		}
	};

	onCancelModal = () => {
		this.setState({
			isVisibleSettlementModal: false,
			fileMapperData: {
				files: [],
				data: [],
			},
		});
	};

	onFinish = async (values: any) => {
		if (this.state.fileMapperData.data.length == 0) {
			this.setState({ imageIsEmpty: true });
			return;
		}

		this.setState({
			loading: true,
			isVisible: false,
			isVisibleSettlementModal: false,
		});
		var images: any = [];
		this.state.fileMapperData.files.forEach((element: any) => {
			images.push({ fileName: element.name });
		});

		const form = new FormData();
		var claimDetails = {
			loan: { id: this.state.selectedClaim.id },
			settleDocument: images,
		};

		this.state.fileMapperData.files.forEach((element: any) => {
			form.append("files", element);
		});

		form.append("loan", JSON.stringify(claimDetails));

		var response = await this.props.settleLoan(form);

		if (response) {
			window.location.reload();
		} else {
			message.error("error");
			this.setState({ loading: false });
		}
	};

	onFinishFailed = (errorInfo: any) => {
		console.log("Failed:", errorInfo);
	};

	onMultipleUploaderSubmit = (data: UploaderState) => {
		this.setState({
			imageIsEmpty: false,
			fileMapperData: data,
		});
	};

	onNoteChange = (e: any) => {
		this.setState({ note: e.target.value });
	};

	renderGuarantorView = (data: any) => {
		return (
			<>
				Name: {data.name} <br />
				Department: {data.departmentName} <br />
				Approved Time: {" "}{moment(data.approvedTime).format("YYYY-MM-DD LT")}
				<br />
			</>
		);
	};

	render() {
		return (
			<div>
				<Spin
					size='small'
					tip='Data Loading'
					spinning={this.state.loading}
					delay={500}>
					<div style={{ opacity: this.state.loading ? 0.2 : 1 }}>
						<Row gutter={[10, 10]}>
							{this.props.DashboardRepo.summaryData &&
								this.props.DashboardRepo.summaryData.length > 0 &&
								this.props.DashboardRepo.summaryData.map((key: any, i: number) => (
									<Col key={i} {...smallCardMediaSpans}>
										<Elements.ValueCard
											header={key.title}
											backgroundColor={getColor(i)}
											icon={<FileTextOutlined />}>
											{key.value}
										</Elements.ValueCard>
									</Col>
								))}
						</Row>

						<Descriptions
							title=''
							style={{ marginTop: 10, marginBottom: 10 }}
							bordered>
							<Descriptions.Item label='Bank Details'>
								Name: Lion Brewery Sports & Welfare / Bank: Hatton National Bank /
								Branch: Kaduwela / Account No: 0820100003351
							</Descriptions.Item>
						</Descriptions>

						<Row gutter={10}>
							<Col span='24'>
								<Col className='row-layout-claim-detail'>
									<Table
										size='small'
										style={{ marginTop: 26, padding: 5 }}
										dataSource={this.state.claims}>
										{/* <Column title="" dataIndex="note" render={(value, item , index) =>  index + 1}  />  */}
										<Column
											title='Loan Id'
											dataIndex='id'
											render={(value, item, index) => value}
										/>
										<Column
											title='Loan Amount'
											dataIndex='loanAmount'
											render={(value, item, index) =>
												"Rs. " +
												(Math.round(value * 100) / 100)
													.toFixed(2)
													.toString()
													.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
											}
										/>
										<Column
											title='Outstanding Amount'
											dataIndex='remainingAmount'
											render={(value, item, index) =>
												"Rs. " +
												(Math.round(value * 100) / 100)
													.toFixed(2)
													.toString()
													.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
											}
										/>
										<Column
											title='Loan Period(M)'
											dataIndex='loanPeriod'
											render={(value, item, index) => value}
										/>
										{/* <Column title="Interest Rate" dataIndex="interestRate" render={(value, item , index) =>  value}  />  */}
										<Column
											width={100}
											title='HR Loan Queue Number '
											dataIndex='approvalListNumber'
											render={(value, item, index) => value}
										/>
										<Column
											title='Created Date'
											dataIndex='createdOn'
											render={(value, item, index) => moment(value).format("YYYY-MM-DD LT")}
										/>

										<Column
											title='Status'
											width={300}
											dataIndex='processingStatus'
											render={(value, item, index) => {
												// if (value === -8) return "CHEQUE IS READY";
												// if (value === 13) return "LOAN SETTLED";
												// return state_value[value];

												return getStatusByCode(parseInt(value))?.title;
											}}
										/>

										<Column
											title='Action'
											key='index'
											render={(value, item, index) => (
												<div>
													<span>
														<a onClick={() => this.viewDetails(value.id)}>
															<Tag color='#1890ff'> View</Tag>
														</a>
													</span>
													{(value.processingStatus == 8 || value.processingStatus == 11) && (
														<span>
															<a onClick={() => this.settleLoan(value.id)}>
																<Tag color='#5ab92c'> Settle Loan</Tag>
															</a>
														</span>
													)}
												</div>
											)}
										/>
									</Table>
								</Col>
							</Col>
						</Row>
					</div>
				</Spin>

				<Drawer
					width="auto"
					placement='right'
					closable={false}
					onClose={this.onClose}
					visible={this.state.visible}>
					{!this.state.editClaim && (
						<div>
							<div id='btn-sendtohod'>
								{this.state.selectedClaim.processingStatus == "0" && (
									<Button
										onClick={() => this.sendToApproval(this.state.selectedClaim.id)}
										style={{ background: "#5ab92c", margin: 4, color: "#fff" }}>
										Send to Approval
									</Button>
								)}
								{this.state.selectedClaim.processingStatus == "6" && (
									<Button
										style={{ margin: 4 }}
										onClick={() => this.resubmitClaim(this.state.selectedClaim.id)}>
										Resubmit Loan
									</Button>
								)}
							</div>
							<br />
							<br />
							{this.state.selectedClaim &&
								this.state.selectedClaim?.gurantors?.length === 2 && (
									<div>
										<Descriptions
											size='small'
											title='Loan Details '
											column={2}
											style={{ marginTop: 10 }}
											bordered>
											{/* <Descriptions.Item label='Guarantor I'>
											{this.state.gurantor01 ?? "N/A"}
										</Descriptions.Item>
										<Descriptions.Item label='Department'>
											{this.state.departmentName01 ?? "N/A"}
										</Descriptions.Item>
										<Descriptions.Item label='Guarantor II'>
											{this.state.gurantor02 ?? "N/A"}
										</Descriptions.Item>
										<Descriptions.Item label='Department'>
											{this.state.departmentName02 ?? "N/A"}
										</Descriptions.Item> */}
											<Descriptions.Item label='Guarantor I'>
												{this.renderGuarantorView(this.state.selectedClaim.gurantors[0])}
											</Descriptions.Item>
											<Descriptions.Item label='Guarantor II'>
												{this.renderGuarantorView(this.state.selectedClaim.gurantors[1])}
											</Descriptions.Item>
											<Descriptions.Item label='Loan Id'>
												{this.state.selectedClaim.id ?? "N/A"}
											</Descriptions.Item>
											<Descriptions.Item label='Note'>
												{this.state.selectedClaim.note ?? "N/A"}
											</Descriptions.Item>
											<Descriptions.Item label='Installment Amount'>
												{this.state.selectedClaim.installmentAmount ?? "N/A"}
											</Descriptions.Item> 
											<Descriptions.Item label='Interest Rate'>
												{this.state.selectedClaim.interestRate ?? "N/A"}
											</Descriptions.Item>
											<Descriptions.Item label='Loan Amount'>
												{this.state.selectedClaim.loanAmount ?? "N/A"}
											</Descriptions.Item>
											<Descriptions.Item label='Remaining Loan Amount'>
												{this.state.selectedClaim.remainingAmount ?? "N/A"}
											</Descriptions.Item>
											{this.state.selectedClaim.createdOn && (
												<Descriptions.Item label='Loan Created On'>
													{moment(this.state.selectedClaim.createdOn).format("YYYY-MM-DD LT")}
												</Descriptions.Item>
											)}
											{this.state.selectedClaim.dateOfChequ && (
												<Descriptions.Item label='Cheque Issued Date'>
													{moment(this.state.selectedClaim.dateOfChequ).format("YYYY-MM-DD LT")}
												</Descriptions.Item>
											)}
											<Descriptions.Item label='Cheque No'>
												{this.state.selectedClaim.chequNumber ?? "N/A"}
											</Descriptions.Item>
										</Descriptions>
									</div>
								)}
							{(this.state.selectedClaim.processingStatus == "6" ||
								this.state.selectedClaim.processingStatus == "11") && (
								<div>
									<div style={{ marginTop: 20 }} className='ant-descriptions-title'>
										Rejected Reason
									</div>
									<h3 style={{ marginTop: 20, color: "#f1242e" }}>
										{this.state.selectedClaim.rejectReason}
									</h3>
								</div>
							)}
						</div>
					)}
					{this.state.editClaim && (
						<div>
							<Row>
								<div style={{ marginTop: 20 }} className='ant-descriptions-title'>
									Request Reason
								</div>
								<TextArea
									maxLength={100}
									rows={4}
									onChange={this.onNoteChange}
									value={this.state.note}
								/>
								<Col span={11} style={{ marginTop: 20 }}>
									<Form {...layout} name='control-ref'>
										<h4 style={{ marginBottom: 20, marginLeft: 30 }}>First Guarantor</h4>
										<Form.Item
											label='Department'
											rules={[
												{
													required: true,
												},
											]}>
											<Select
												style={{ width: 250 }}
												onChange={this.departmentChange}
												showSearch
												optionFilterProp='children'
												value={this.state.department01}>
												{this.state.departments.map((key: any) => (
													<Select.Option value={key.id}>
														{key.eclaimHiecodeName}
													</Select.Option>
												))}
											</Select>
										</Form.Item>
										<Form.Item
											label='Name'
											rules={[
												{
													required: true,
												},
											]}>
											<Select
												style={{ width: 250 }}
												onChange={this.firstGuarantorChange}
												showSearch
												optionFilterProp='children'
												value={this.state.firstGuarantorId}>
												{this.state.firstGuarantor.map((key: any) => (
													<Select.Option value={key.id}>{key.name}</Select.Option>
												))}
											</Select>
										</Form.Item>
									</Form>
								</Col>

								<Col span={11} style={{ marginTop: 20 }}>
									<Form {...layout} name='control-ref'>
										<h4 style={{ marginBottom: 20, marginLeft: 30 }}>Second Guarantor</h4>
										<Form.Item
											label='Department'
											rules={[
												{
													required: true,
												},
											]}>
											<Select
												style={{ width: 250 }}
												onChange={this.GuarantorTwodepartmentChange}
												showSearch
												optionFilterProp='children'
												value={this.state.department02}>
												{this.state.departments.map((key: any) => (
													<Select.Option value={key.id}>
														{key.eclaimHiecodeName}
													</Select.Option>
												))}
											</Select>
										</Form.Item>
										<Form.Item
											label='Name'
											rules={[
												{
													required: true,
												},
											]}>
											<Select
												style={{ width: 250 }}
												onChange={this.secondGuarantorChange}
												showSearch
												optionFilterProp='children'
												value={this.state.secondGuarantorId}>
												{this.state.secondGuarantor.map((key: any) => (
													<Select.Option value={key.id}>{key.name}</Select.Option>
												))}
											</Select>
										</Form.Item>
									</Form>
								</Col>
								<Button
									type='primary'
									onClick={() => this.editLoan(this.state.selectedClaim.id)}>
									Edit Loan
								</Button>
							</Row>
						</div>
					)}
					<div id='header-content'>
						{this.state.editClaim == true && (
							<Button onClick={this.view} style={{ margin: 4 }}>
								View Loan
							</Button>
						)}
						{this.state.selectedClaim.processingStatus == 0 &&
							this.state.editClaim == false && (
								<Button onClick={this.edit} style={{ margin: 4 }}>
									Edit Loan
								</Button>
							)}
						{this.state.selectedClaim.processingStatus == 0 && (
							<Popconfirm
								title='Are you sure to delete this loan?'
								onConfirm={() => this.deleteLoanRequest(this.state.selectedClaim.id)}
								okText='Yes'>
								<Button type='primary' danger style={{ margin: 4 }}>
									Delete Loan
								</Button>
							</Popconfirm>
						)}
					</div>
				</Drawer>

				<Modal
					title={"Settle Loan"}
					style={{ top: 20 }}
					visible={this.state.isVisibleSettlementModal}
					onCancel={() => this.onCancelModal()}
					footer={null}
					destroyOnClose={true}>
					<Form
						{...layout}
						initialValues={{
							remember: false,
						}}
						onFinish={this.onFinish}
						onFinishFailed={this.onFinishFailed}>
						<Form.Item label='Outstanding ' name='remainingAmount'>
							<Input
								placeholder={
									"Rs. " +
									(Math.round(this.state.outstanding_amount * 100) / 100)
										.toFixed(2)
										.toString()
										.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
								}
								disabled
							/>
						</Form.Item>
						<Form.Item label='Attach Bank Slip'>
							<MultipleFileUploader
								accept={[".png", ".jpeg", ".jpg", ".PNG", ".JPG", ".JPEG"]}
								heading='Click or drag file to this area to upload'
								onChange={this.onMultipleUploaderSubmit}
							/>
						</Form.Item>
						{this.state.imageIsEmpty && (
							<p style={{ color: "#ff4d4f" }}>
								* please upload the relevant bank slips{" "}
							</p>
						)}
						<Form.Item>
							<Button type='primary' htmlType='submit'>
								Submit
							</Button>
						</Form.Item>
					</Form>
				</Modal>
			</div>
		);
	}
}

const WrappedDashboardView = Dashboard;
const withDashboardRepo = connect(
	(state: any) => state,
	DashboardActions
)(WrappedDashboardView);

export default withDashboardRepo;
